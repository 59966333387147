import { useState } from 'react';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

export default function DismissableAlert({children, severity}) {
  const [open, setOpen] = useState(true);

  return (
    <Box sx={{ width: '100%', display: open ? 'block' : 'none' }}>
      <Alert
        severity={severity}
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              setOpen(false);
            }}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
        sx={{ mb: 2, width: '100%' }}
      >
        {children}
      </Alert>
    </Box>
  );
}
