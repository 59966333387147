import { useErrorTracking } from "hooks/useErrorTracking";
import { useNavigate } from 'react-router-dom';
import { useDeepCompareEffect } from 'react-use';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import useContractsDataAPI from 'hooks/useContractsDataAPI';
// 3rd Party Components
import Box from '@mui/material/Box';
// Our Components
import GridContainer from 'components/_common/GridContainer';
import GridItem from 'components/_common/GridItem';
import BodyText from 'components/_common/text/BodyText';
import DashboardHeading from 'components/_common/text/DashboardHeading';
import DataTable from 'components/_common/DataTable/DataTable';
import ButtonMenu from 'components/_common/ButtonMenu';
import DocumentStatusBadge from "components/ContractDocument/DocumentStatusBadge";
import { Typography } from "@mui/material";
import useAppContext from "hooks/useAppContext";


/**
 * Empty page layout document (no app bar, no sidebar)
 */
export default function UserDocuments({children}) {
  const { models, enums } = useContractsDataAPI();
  const [{infoModal}, {setInfoModal}] = useAppContext();
  const { ContractDocumentStatus } = enums;

  const currentUser = useSelector(state => state.currentUser);
  const userId = currentUser?.profile?.id;
  const navigate = useNavigate();
  const trackError = useErrorTracking();
  let userDocuments = [];

  const { isLoading, isSuccess, isError, error, data } = useQuery('userDocuments',
    () => models.user.getDocuments(userId)
  );

  useDeepCompareEffect(() => {
    if (isError) {
      console.error(error);
      trackError(error);
    }
  }, [isError, error]);

  if (isSuccess) {
    userDocuments = [...data];
  }

  const StatusDescTitle = ({status}) => (
    <Box sx={{bgcolor: '#eaeaea', padding: '2px 8px', display: 'inline-block', borderRadius: '2px', mb: 1}}>
      <Typography variant="body1" sx={{fontWeight: 500, fontSize: '16px'}}>{status}</Typography>
    </Box>
  )

  const StatusDesc = ({text}) => (
    <Typography variant="body1" sx={{mb: 2}}>{text}</Typography>
  )

  const getStatusDescriptions = () => (
    <Box sx={{
      pt: 1,
      backgroundColor: 'white',
    }}>
      <StatusDescTitle status="Creating" />
      <StatusDesc text="The document is being created." />
      <StatusDescTitle status="Draft" />
      <StatusDesc text="The document is created but is not yet ready to view." />
      <StatusDescTitle status="Created" />
      <StatusDesc text="The document is created and ready to view/sign." />
      <StatusDescTitle status="Sent" />
      <StatusDesc text="You have filled and signed your fields on the document and it has been sent to the other party to be filled/signed." />
      <StatusDescTitle status="Completed" />
      <StatusDesc text="The document has been signed by everyone. It may still require payment of the signing fee before it can be viewed or downloaded." />
    </Box>
  )

  const handleStatusClick = () => {
    setInfoModal({
      isOpen: true,
      title: 'Document Status',
      body: getStatusDescriptions()
    });
  }

  const actionMenuContent = (rowData) => {
    const isPaid =
      rowData.documentStatus === ContractDocumentStatus.Completed
      && rowData.signingFeeStripePaymentId;

    const menuItems = [
      {
        type: 'menuItem',
        text: isPaid ? 'View/Download' : 'View',
        onClick: () => { navigate(`/d/${rowData.id}/user`) },
        key: `${rowData.id}-view-document`,
        icon: null
      }
    ];

    return menuItems;
  };

  const renderActionsButton = (params) => {
    return (
      <ButtonMenu size="small" text="Actions" menuContent={actionMenuContent(params.row)} id={params.id} />
    )
  }

  const renderStatusBadge = (params) => {
    return (
      <Box sx={{'&:hover': {cursor: 'pointer'}}} onClick={handleStatusClick}>
        <DocumentStatusBadge contractDocument={params.row}/>
      </Box>
    )
  }

  const renderTitle = (params) => {
    return (
      <Box
        component="span"
        onClick={() => { navigate(`/d/${params.id}/user`) }}
        sx={{'&:hover': { cursor: 'pointer' }}}
      >
        {params.row.title}
      </Box>
    )
  }

  const getClientEmail = ({row}) => {
    if (row.recipientEmails.length) {
      return row.recipientEmails.find(re => re !== currentUser?.profile?.email);
    }
  }

  const renderClientEmail = ({row}) => {
    if (row.recipientEmails.length) {
      return (
        <Box
          component="span"
          sx={{overflow: 'hidden', textOverflow: 'ellipsis'}}
        >
          {row.recipientEmails.find(re => re !== currentUser?.profile?.email)}
        </Box>
      )
    }
  }

  // https://mui.com/x/react-data-grid/column-definition/
  const columns = [
    { field: models.contractDocument.fields.title, headerName: 'Document', renderCell: renderTitle, flex: 3 },
    { field: 'clientEmail', headerName: 'Client Email', valueGetter: getClientEmail, renderCell: renderClientEmail, flex: 1 },
    { field: 'status', headerName: 'Status', renderCell: renderStatusBadge, width: 125 },
    { field: 'actions', headerName: '', renderCell: renderActionsButton, width: 125 }
  ];

  return (
    <Box>
      <GridContainer>
        <GridItem>
          <DashboardHeading text="My Documents" />
          <BodyText>
            Here you'll find any documents you've created from your contract templates
          </BodyText>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12}>
          <DataTable
            rows={userDocuments}
            columns={columns}
            isLoading={isLoading}
            isError={isError}
            disableColumnMenu
            textSearchFields={['title', 'clientEmail']}
            textSearchLabel="Search Titles & Client Emails"
            noRowsLabel="No Documents Found"
          />
        </GridItem>
      </GridContainer>
    </Box>
  );
}
