import { memo } from 'react';
import useAppContext from 'hooks/useAppContext';
import InfoDialog from './_common/dialogs/InfoDialog';
import BodyText from './_common/text/BodyText';
import { APP_COMPANY_NAME } from 'config/global';
import SubmitButton from './_common/formik/SubmitButton';
import useContractsRestAPI from 'hooks/useContractsRestAPI';
import { useEffect, useState } from 'react';
import { useErrorTracking } from 'hooks/useErrorTracking';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { sleep } from 'utils/sleep';
import { ERROR_SNACKBAR_OPTIONS } from 'config/notistack';
import { ReactComponent as StripeLogo } from 'assets/img/stripe-logo.svg';
import { Alert, AlertTitle, Box } from '@mui/material';
import DelayedRender from './_common/DelayedRender';
import Spinner from './_common/Spinner';

const StripeConnectDialog = ({ isOpen, onClose }) => {
  const { profile } = useSelector((state) => state.currentUser);
  const [error, setError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasStartedOnboarding, setHasStartedOnboarding] = useState(undefined);
  const [stripeAccountInfo, setStripeAccoutnIfno] = useState(undefined);
  const trackError = useErrorTracking();
  const { enqueueSnackbar } = useSnackbar();
  const apiClient = useContractsRestAPI();

  const stripeConnectAccountId = profile.stripeConnectAccountId;
  const stripeConnectChargesEnabled = profile.stripeConnectChargesEnabled;

  const stripeOnboardingNotStarted = !stripeConnectAccountId || hasStartedOnboarding === false;
  const stripeApplicationUnfinished = stripeConnectAccountId && !stripeConnectChargesEnabled && hasStartedOnboarding && !stripeAccountInfo?.details_submitted;
  const stripeVerificationIncomplete = stripeConnectAccountId && !stripeConnectChargesEnabled && hasStartedOnboarding && stripeAccountInfo?.details_submitted;

  // console.log('stripeAccountInfo', stripeAccountInfo);
  // console.log('stripeOnboardingNotStarted', stripeOnboardingNotStarted);
  // console.log('stripeVerificationIncomplete', stripeVerificationIncomplete);

  const handleClose = async () => {
    onClose();
    await sleep(1000);
    setHasStartedOnboarding(undefined);
    setError('');
  }

  useEffect(() => {
    (async () => {
      if (isOpen) {
        if (stripeConnectAccountId) {
          let theStripAccountInfo;

          try {
            theStripAccountInfo = await apiClient.post('/stripe/get-connected-account', {
              stripeConnectAccountId
            });

            setStripeAccoutnIfno(theStripAccountInfo);

            if (!theStripAccountInfo.email) {
              setHasStartedOnboarding(false);
            } else {
              setHasStartedOnboarding(true);
            }
          } catch (error) {
            trackError(error);
            setError(error.message);
          }
        } else {
          setHasStartedOnboarding(false);
        }
      }
    })()
  }, [stripeConnectAccountId, isOpen]);

  const handleButtonClick = async () => {
    try {
      setIsSubmitting(true);

      if (!stripeConnectAccountId) {
        await apiClient.get('/stripe/create-connect-account');
        await sleep(1000);
      }

      const { stripeAccountLinkURL } = await apiClient.post(
        '/stripe/account-link',
        {
          returnURL: `${window.location.href}?stripe-link-return=true`,
        }
      );

      window.location.href = stripeAccountLinkURL;
    } catch (error) {
      setIsSubmitting(false);
      trackError(error);
      enqueueSnackbar(
        `Error creating Stripe connection`,
        ERROR_SNACKBAR_OPTIONS
      );
    }
  };

  const getButtonText = () => {
    if (stripeOnboardingNotStarted) return "Get Started";
    if (stripeApplicationUnfinished) return "Continue Stripe Setup";
    if (stripeVerificationIncomplete) return "Review / Edit Details";
  }

  return (
    <InfoDialog
      title="Connect to Stripe"
      isOpen={isOpen}
      handleClose={handleClose}
    >
      <>
        {hasStartedOnboarding === undefined ? (
          <Box
            sx={{
              marginTop: '75px',
              marginBottom: '75px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            {error ? (
              <Alert severity="error">
                <AlertTitle>Error</AlertTitle>
                {error}
              </Alert>
            ) : (
              <DelayedRender delay={200}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                  }}
                >
                  {/* <h3>Loading</h3> */}
                  <Spinner />
                </Box>
              </DelayedRender>
            )}
          </Box>
        ) : (
          <>
            {stripeOnboardingNotStarted && (
              <BodyText sx={{ mb: 4 }}>
                {APP_COMPANY_NAME} partners with{' '}
                <a
                  href="https://stripe.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Stripe
                </a>{' '}
                for secure payments. If you'd like to accept your own payments
                for fees associated with documents that you create here in the
                app, you can connect your account here with Stripe. You'll need
                to have your bank account details and some other general
                business information available to complete the sign up process.
                We do not take a cut of any payments you collect through Stripe,
                but Stripe will keep their usual processing fee. Click the
                button below to get started.
              </BodyText>
            )}
            {stripeApplicationUnfinished && (
              <BodyText sx={{ mb: 4 }}>
                It looks like you previously started the process of connecting
                your account with Stripe but didn't finish or Stripe needs to
                verify additional information. You'll need to have your bank
                account details and some other general business and personal
                information available to complete the sign up process. Click the
                button below to finish setting up the Stripe connection.
              </BodyText>
            )}
            {stripeVerificationIncomplete && (
              <BodyText sx={{ mb: 4 }}>
                It looks like you submitted your info to Stripe but your account
                is not fully enabled yet. They may still be verifying your
                information. Sometimes this takes a little time and{' '}
                {APP_COMPANY_NAME} has no control over the Stripe verification
                process. Sorry for any inconvienience this may cause. Click the
                button below to review your submitted information and look for
                any items that say "Pending verification" or something similar.
                You may also reach out to{' '}
                <a
                  href="https://support.stripe.com/?contact=true"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Stripe Support
                </a>{' '}
                for assistance finishing the verification process.
              </BodyText>
            )}
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Box>
                <SubmitButton
                  submitting={isSubmitting}
                  onClick={handleButtonClick}
                >
                  {getButtonText()}
                </SubmitButton>
              </Box>
              <Box>
                <StripeLogo width="80px" />
              </Box>
            </Box>
          </>
        )}
      </>
    </InfoDialog>
  );
};

export default memo(StripeConnectDialog);

