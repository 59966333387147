import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
// import "firebase/compat/database";
// import "firebase/compat/functions";
// import "firebase/compat/auth";
// import "firebase/compat/storage";
import { Contracts_DataApi } from "@jsr-law/contracts-api";
import { firebaseConfig } from 'config/firebaseConfig';
import { RUNTIME_ENV, USE_EMULATORS } from "config/global";
import { firebaseHelpers } from "utils/firebaseUtils";

firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();
const FieldValue = firebase.firestore.FieldValue;
const FieldPath = firebase.firestore.FieldPath;
const Timestamp = firebase.firestore.Timestamp;
const firestoreHelpers = {FieldValue, FieldPath, Timestamp};

// db.enablePersistence

console.log('RUNTIME_ENV', RUNTIME_ENV);
console.log('USE_EMULATORS', USE_EMULATORS);

if (RUNTIME_ENV === "local" && USE_EMULATORS === 'true') {
  db.useEmulator("localhost", 8080);
}

/**@type {Contracts_DataApi} */
const contractsAPI = new Contracts_DataApi(db, firestoreHelpers);

contractsAPI.selectOptions = {
  contractTemplates: () => firebaseHelpers.rtdb.get('selectOptions/contractTemplates')
}

export default contractsAPI;
