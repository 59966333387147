import { useState } from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import GridContainer from 'components/_common/GridContainer';
import GridItem from 'components/_common/GridItem';
import BodyText from 'components/_common/text/BodyText';
import DashboardHeading from 'components/_common/text/DashboardHeading';
import { Card, CardContent, Divider } from "@mui/material";
import VerifiedIcon from '@mui/icons-material/Verified';
import Button from "components/_common/Button";
import StripeConnectDialog from "components/StripeConnectDialog";


/**
 * Empty page layout document (no app bar, no sidebar)
 */
export default function UserSettings() {
  const [ stripeDialogOpen, setStripeDialogOpen ] = useState(false);
  const profile = useSelector(state => state.currentUser.profile);

  return (
    <Box>
      <GridContainer>
        <GridItem>
          <DashboardHeading text="Account Settings" />
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12}>
          <Card sx={{maxWidth: '400px'}}>
            <CardContent>
              <BodyText sx={{fontSize: '20px', fontWeight: 'bold'}}>
                User Details
              </BodyText>
              <Divider sx={{my: 1}} />
              <BodyText sx={{mb: 1}}>
                <Box component="span" sx={{fontWeight: '500'}}>Name: </Box>
                {profile.displayName}
              </BodyText>
              <BodyText sx={{mb: 1}}>
                <Box component="span" sx={{fontWeight: '500'}}>Email: </Box>
                {profile.email}
              </BodyText>
            </CardContent>
          </Card>
        </GridItem>
        <GridItem xs={12}>
          <Card sx={{maxWidth: '400px'}}>
            <CardContent>
              <BodyText sx={{fontSize: '20px', fontWeight: 'bold'}}>
                Stripe Account
              </BodyText>
              <Divider sx={{my: 1}} />
              {profile.stripeConnectAccountId && profile.stripeConnectChargesEnabled
                ?
                <Box sx={{display: 'flex', alignItems: 'center'}}>
                  <BodyText>Connected</BodyText>
                  <VerifiedIcon sx={{ml: 1}} fontSize="inherit" color="success" />
                </Box>
                :
                <Box sx={{mt: 2}}>
                  <Button
                    size="small"
                    onClick={() => setStripeDialogOpen(true)}
                  >
                    {!profile.stripeConnectAccountId &&
                      'Connect Stripe'
                    }
                    {(profile.stripeConnectAccountId && !profile.stripeConnectChargesEnabled) &&
                      'View Status'
                    }
                  </Button>
                </Box>

              }
            </CardContent>
          </Card>
        </GridItem>
      </GridContainer>
      <StripeConnectDialog isOpen={stripeDialogOpen} onClose={() => setStripeDialogOpen(false)} />
    </Box>
  );
}
