import { useEffect } from 'react';
import { useErrorTracking } from "hooks/useErrorTracking";
import { useDeepCompareEffect } from 'react-use';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import useContractsDataAPI from 'hooks/useContractsDataAPI';
import { Box } from '@mui/system';
// import GridContainer from 'components/_common/GridContainer';
// import GridItem from 'components/_common/GridItem';
import BasicTemplate from 'components/_common/BasicTemplate';
import H1 from 'components/_common/text/H1';
import BodyText from 'components/_common/text/BodyText';


import BlurredContractImg from 'assets/img/blurred-contract-2.jpg';
import CreateDocumentForm from "components/forms/user/CreateDocumentForm";
import { SUPPORT_EMAIL } from "config/global";
import useAppContext from "hooks/useAppContext";
import { useTheme } from '@emotion/react';
import { useMediaQuery } from '@mui/material';

/// <reference path="types/misc.js" />

export default function ContractTemplate() {
  const { profile, auth, isLoading: authIsLoading } = useSelector(state => state.currentUser);
  const [appContext, {setActionMenu}] = useAppContext(); // eslint-disable-line
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.only('xs'));
  const { templateId } = useParams();
  const { models } = useContractsDataAPI();
  const navigate = useNavigate();
  // const navigate = useNavigate();
  const trackError = useErrorTracking();
  const userHasTemplate = profile?.contractTemplates && profile.contractTemplates.includes(templateId);
  let contractTemplate = {};

  const { isLoading, isSuccess, isError, error, data } = useQuery(['userContractTemplate', templateId],
    () => models.contractTemplate.get(templateId)
  );

  useEffect(() => {
    /**@type {ButtonMenuItem} */
    const exitToDashboard = {
      key: 'exitToDashboard',
      text: 'Exit to Dashboard',
      onClick: () => navigate('/u/templates')
    }

    setActionMenu({
      enabled: true,
      items: [exitToDashboard]
    });
  }, [navigate, setActionMenu]);

  useDeepCompareEffect(() => {
    if (isError) {
      console.error(error);
      trackError(error);
    }
  }, [isError, error]);

  if (isSuccess) {
    contractTemplate = {...data};
  }

  if (!auth && authIsLoading) return null;

  return (
    <BasicTemplate>
      {(!isLoading && !userHasTemplate) &&
        <Box
          sx={{
            // marginTop: '25vh',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
            maxWidth: '800px',
            margin: '25vh auto 0'
          }}
        >
          <H1 sx={{mb: 2}}>Uh oh!</H1>
          <BodyText sx={{textAlign: 'center', fontSize: '18px'}}>
            Looks like you don't have access to <strong>{contractTemplate.title}</strong>.{' '}
            If you believe you are supposed to, please contact us at <a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a>.
          </BodyText>
        </Box>
      }
      {(!isLoading && userHasTemplate) &&
        <Box
          sx={{
            mt: 2,
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              position: 'relative'
            }}
          >
            <img src={BlurredContractImg} alt="" style={{width: '100%', maxWidth: '1200px', flex: '1'}} />
            {/* <Box sx={{
              content: '" "',
              zIndex: 2,
              backgroundColor: 'rgba(0,0,0, 0.05)',
              position: 'absolute',
              top: '0px',
              left: '0px',
              height: '100%',
              width: '100%'
            }} /> */}
            <Box sx={{zIndex: 3, position: 'absolute', top: `${isSmallScreen ? '0' : '5vh'}`, maxWidth: '800px'}}>
              <CreateDocumentForm contractTemplate={contractTemplate} />
            </Box>
          </Box>
        </Box>
      }
    </BasicTemplate>
  );
}
