import { Form, Formik } from "formik";
import { useContext } from "react";
import { FormContext } from "../CreateDocumentForm";
import * as yup from "yup";

import { Typography, Box } from "@mui/material";
import CustomFormikField from 'components/_common/formik/CustomFormikField';
import CheckboxBool from 'components/_common/formik/fields/CheckboxBool';
import SubmitButton from "components/_common/formik/SubmitButton";
import H4 from 'components/_common/text/H4';
import Subtitle from 'components/_common/text/Subtitle';
import BodyText from "components/_common/text/BodyText";
import CopyToClipboardButton from "components/_common/CopyToClipboardButton";
import useContractsDataAPI from "hooks/useContractsDataAPI";
import DocumentTitle from "components/ContractDocument/DocumentTitle";

function Terms() {
  const { models } = useContractsDataAPI();

  const {
    activeStepIndex,
    setActiveStepIndex,
    formData,
    setFormData,
    contractTemplate
  } = useContext(FormContext);

  const fieldNames = {
    createDocumentTermsAccepted: models.contractDocument.fields.createDocumentTermsAccepted
  };

  return (
    <Formik
      initialValues={{
        [fieldNames.createDocumentTermsAccepted]: formData.createDocumentTermsAccepted || false,
      }}
      validationSchema={yup.object().shape({
        // https://jasonwatmore.com/post/2020/02/08/react-formik-required-checkbox-example
        [fieldNames.createDocumentTermsAccepted]: yup.bool().oneOf([true], 'Required')
      })}
      onSubmit={(values) => {
        const data = { ...formData, ...values };
        setFormData(data);
        setActiveStepIndex(activeStepIndex + 1);
      }}
    >
      {({ values, isSubmitting }) => (
        <Form>
          <Box sx={{maxWidth: '600px'}}>
            <Box className="text-center" sx={{mb: 3}}>
              <H4 sx={{mb: 1, mx: 'auto'}}>Create Document from Template</H4>
              <DocumentTitle title={contractTemplate.title} maxWidth="100%" />
              <Subtitle sx={{fontWeight: 500}}>
                Terms Acknowledgement
              </Subtitle>
            </Box>
            <Box sx={{mb: 4}}>
              <BodyText className="text-lg" sx={{mb: 2}}>
                Has the Other Party seen an un-filled copy of this agreement yet
                and agreed to your terms (dollar amounts, percentages, etc)?
              </BodyText>
              <BodyText className="italic" sx={{mb: 4}}>
                It's important to work out these details before setting up the
                agreement for signing. Click the button below to copy a link to
                an un-filled PDF copy of this agreement that you can email or text to them.
              </BodyText>
              <Box className="flex justify-center">
                <CopyToClipboardButton
                  buttonText="Copy Template View Link"
                  textToCopy={contractTemplate.pdfUrl}
                />
              </Box>
            </Box>
            <CustomFormikField
              name={fieldNames.createDocumentTermsAccepted}
              label={`Yes the Other Party and I have already discussed and agreed to the \nterms of this agreement`}
              CustomComponent={CheckboxBool}
              margin="normal"
              id={fieldNames.createDocumentTermsAccepted}
            />

            <Box className="flex justify-end" sx={{mt: 6}}>
              <SubmitButton
                type="submit"
              >
                <Typography>Next</Typography>
              </SubmitButton>
            </Box>
          </Box>
        </Form>
      )}
    </Formik>
  );
}

export default Terms;
