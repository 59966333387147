import { useContext } from "react";
import { ContractsAPIContext } from "../index.js";

/**
 * Returns an mc-api instance for working with Firestore
 *
 * Usage: const { models } = useContractsDataAPI();
 */
export default function useContractsDataAPI() {
  return useContext(ContractsAPIContext);
}
