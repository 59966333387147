import { Suspense, lazy, useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Spinner from 'components/_common/Spinner';
import DelayedRender from 'components/_common/DelayedRender';
import { Box } from '@mui/material';
import PrivateRoute from './components/_common/PrivateRoute';
import NotFound from 'layouts/404';
import AppInfoDialog from 'components/AppInfoDialog';
import AppConfirmationDialog from 'components/AppConfirmationDialog';
import AppOverlaySpinner from 'components/AppOverlaySpinner';
import useAppContext from 'hooks/useAppContext';
import { useQueryParams } from 'hooks/useQueryParams';
import { removeQueryStringFromBrowser } from 'utils/remove-query-string-from-browser';

import UserDashboardLayout from './layouts/UserDashboard'
import PublicLayout from './layouts/Public'
import AuthLayout from './layouts/Auth'
import ContractTemplateLayout from './layouts/ContractTemplate'
import ContractDocumentLayout from './layouts/ContractDocument'

// const UserDashboardLayout = lazy(() => import('./layouts/UserDashboard'));
// const PublicLayout = lazy(() => import('./layouts/Public'));
// const AuthLayout = lazy(() => import('./layouts/Auth'));
// const ContractTemplateLayout = lazy(() => import('./layouts/ContractTemplate'));
// const ContractDocumentLayout = lazy(() => import('./layouts/ContractDocument'));
const AdminDashboardLayout = lazy(() => import('./layouts/AdminDashboard'));


function App() {
  console.info('app render');
  const { auth, profile } = useSelector(state => state.currentUser);
  const [appState, {setInfoModal}] = useAppContext();
  const queryParams = useQueryParams();
  const stripeChargesEnabled = profile?.stripeConnectChargesEnabled;
  const isStripeLinkReturn = queryParams['stripe-link-return'] === 'true';

  useEffect(() => {
    if (isStripeLinkReturn && !stripeChargesEnabled) {
      setInfoModal({
        isOpen: true,
        title: 'Stripe Connect Notice',
        body: `
          Your Stripe account setup is in progress. It may take some time for the new
          account to become active and Stripe may request additional verification. You
          can visit your account settings to view the status by clicking on the user
          account menu at the top right of the screen on your account dashboard.
        `
      });
      removeQueryStringFromBrowser();
    }
  }, [isStripeLinkReturn, setInfoModal, stripeChargesEnabled]);

  const Loading = () => (
    <DelayedRender>
      <Box sx={{
        marginTop: '30%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}>
        <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
          <h3>Loading</h3>
          <Spinner />
        </Box>
      </Box>
    </DelayedRender>
  );

  return (
    <Suspense fallback={<Loading />}>
      <Routes>
        <Route path="*" element={<Navigate to="/404" replace />} />
        <Route path="/404" element={<NotFound />} />
        <Route path="/" element={<Navigate to="/u/templates" replace />} />
        <Route path="/*" element={<PublicLayout />} />
        <Route path="/admin" element={<Navigate to="/admin/users" replace />} />
        <Route path="/admin/*" element={
          <PrivateRoute isAllowed={auth?.roles && auth.roles.includes('admin')} redirectPath="/u/templates">
            <AdminDashboardLayout />
          </PrivateRoute>
        } />
        <Route path="/auth/*" element={<AuthLayout />} />
        <Route path="/d/*" element={<ContractDocumentLayout />} />
        <Route path="/t/:templateId" element={<PrivateRoute><ContractTemplateLayout /></PrivateRoute>} />
        <Route path="/u" element={<PrivateRoute><Navigate to="/u/templates" replace /></PrivateRoute>} />
        <Route path="/u/*" element={<PrivateRoute><UserDashboardLayout /></PrivateRoute>} />
      </Routes>
      <AppInfoDialog />
      <AppConfirmationDialog />
      <AppOverlaySpinner />
    </Suspense>
  );
}

export default App;
