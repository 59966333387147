import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const useDelayedRender = delay => {
  const [delayed, setDelayed] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => setDelayed(false), delay);
    return () => clearTimeout(timeout);
  }, [delay]);
  return fn => !delayed && fn();
};

useDelayedRender.defaultProps = {
  delay: 1000
};

useDelayedRender.propTypes = {
  delay: PropTypes.number
};

export default useDelayedRender;
