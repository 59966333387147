import { Routes, Route } from 'react-router-dom';
import BasicTemplate from 'components/_common/BasicTemplate';

export default function PublicLayout() {
  return (
    <BasicTemplate>
      <Routes>
        <Route path="*" element={<div>Public</div>} />
      </Routes>
    </BasicTemplate>
  );
}
