import { useEffect } from "react";
import { firebaseAuth } from "utils/firebaseUtils";
import { signOut } from 'firebase/auth';

export default function SignOut() {
  useEffect(() => {
    (async function() {
      await signOut(firebaseAuth);
      window.location.replace(`${window.location.origin}/auth/login`);
    })();
  }, []);

  return null;
}
