import { Box } from "@mui/material"
import Spinner from "./Spinner"

const OverlaySpinner = ({fullscreen}) => {
  return (
    <Box
      sx={{
        '&::after': {
          position: fullscreen ? 'fixed' : 'absolute',
          width: '100%',
          height: '100%',
          top: 0,
          left: 0,
          content: '" "',
          display: 'block',
          background: 'rgba(255,255,255,.5)',
          zIndex: fullscreen ? 999999 : 10,

        },

      }}
    >
      <Box sx={{
        position: fullscreen ? 'fixed' : 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: fullscreen ? 9999999 : 15,
      }}>
        <Spinner />
      </Box>
    </Box>
  )
}

export default OverlaySpinner;
