import * as Sentry from "@sentry/react";
import { useCallback } from "react";

export const useErrorTracking = () => {
  const trackError = useCallback((error) => {
    console.error(error);
    Sentry.captureException(error);
  }, []);

  return trackError;
};

