import { Form, Formik } from "formik";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { useErrorTracking } from "hooks/useErrorTracking";
import dayjs from 'dayjs';

import { Box, TextField } from "@mui/material";
import CustomFormikField from 'components/_common/formik/CustomFormikField';
import SubmitButton from "components/_common/formik/SubmitButton";
import H4 from 'components/_common/text/H4';
import BodyText from "components/_common/text/BodyText";
import Button from "components/_common/Button";
import useContractsDataAPI from "hooks/useContractsDataAPI";
import GridContainer from 'components/_common/GridContainer';
import GridItem from 'components/_common/GridItem';
import DocumentTitle from "components/ContractDocument/DocumentTitle";
import { FormContext } from "../CreateDocumentForm";
import { RUNTIME_ENV } from "config/global";

/**
 * Form step for entering "Other Party" information
 * when creating a new document from a Contract Template
 */
function ClientStep() {
  const navigate = useNavigate();
  const { models, enums } = useContractsDataAPI();
  const { ContractDocumentRoles } = enums;
  const trackError = useErrorTracking();

  const {
    activeStepIndex,
    setActiveStepIndex,
    formData,
    contractTemplate
  } = useContext(FormContext);

  const todaysDate = dayjs().format('YYYY-MM-DD');

  const createDocumentName = (clientName) => {
    const firstName = formData.recipients[0].first_name;
    const lastName = formData.recipients[0].last_name;
    const devEnv = RUNTIME_ENV === 'local' || RUNTIME_ENV === 'development' ? '[dev] ' : '';
    return `${devEnv}${todaysDate} ${contractTemplate.shortTitle} [${firstName} ${lastName} + ${clientName}]`;
  };

  const formFields = {
    email: 'email',
    first_name: 'first_name',
    last_name: 'last_name'
  };

  return (
    <Formik
      initialValues={{
        [formFields.email]: '', // pandadoc field
        [formFields.first_name]: '', // pandadoc field
        [formFields.last_name]: '', // pandadoc field
      }}
      validationSchema={yup.object().shape({
        [formFields.email]: yup.string().email('Please enter a valid email address').required('required'),
        [formFields.first_name]: yup.string().required('required'),
        [formFields.last_name]: yup.string().required('required'),
      })}
      onSubmit={async (values, {setSubmitting}) => {
        // console.log('onSubmit values', values);

        const data = {
          ...formData,
          title: createDocumentName(`${values.first_name} ${values.last_name}`),
          recipients: [
            ...formData.recipients,
            {
              email: values.email,
              first_name: values.first_name,
              last_name: values.last_name,
              role: ContractDocumentRoles.Client // TODO: make this dynamic?
            }
          ],
          recipientEmails: [...formData.recipientEmails, values.email]
        };

        // console.log('data', data);

        try {
          const contractDoc = await models.contractDocument.create(data, true);
          navigate(`/d/${contractDoc.id}/user`);
        } catch(e) {
          console.error(e);
          trackError(e);
        }

        setSubmitting(false);

      }}
    >
      {({ values, isSubmitting }) => (
        <Form>
          <Box sx={{maxWidth: '600px'}}>
            <Box className="text-center" sx={{mb: 4}}>
              <H4 sx={{mb: 1, mx: 'auto'}}>Create Document from Template</H4>
              <DocumentTitle title={contractTemplate.title} maxWidth="100%" />
              <BodyText sx={{maxWidth: '450px', mx: 'auto'}}>
                Enter the Other Party's info and click "Review Document" to begin document creation
              </BodyText>
            </Box>
            <Box sx={{mb: 2}}>
              <BodyText className="text-xl font-medium" sx={{mb: 2}}>
                Other Party Info
              </BodyText>
            </Box>
            <GridContainer>
              <GridItem xs={12}>
                <CustomFormikField
                  name={formFields.email}
                  label="Email"
                  CustomComponent={TextField}
                  margin="normal"
                  fullWidth
                  id={formFields.email}
                  required
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} md={6}>
                <CustomFormikField
                  name={formFields.first_name}
                  label="First Name"
                  CustomComponent={TextField}
                  margin="normal"
                  fullWidth
                  id={formFields.first_name}
                  required
                />
              </GridItem>
              <GridItem xs={12} md={6}>
                <CustomFormikField
                  name={formFields.last_name}
                  label="Last Name"
                  CustomComponent={TextField}
                  margin="normal"
                  fullWidth
                  id={formFields.last_name}
                  required
                />
              </GridItem>
            </GridContainer>
            <Box className="flex justify-between" sx={{mt: 6}}>
              <Button
                onClick={() => setActiveStepIndex(activeStepIndex - 1)}
              >
                Back
              </Button>
              <SubmitButton
                submitting={isSubmitting}
                type="submit"
              >
                Review Document
              </SubmitButton>
            </Box>
          </Box>
        </Form>
      )}
    </Formik>
  );
}

export default ClientStep;
