import PropTypes from 'prop-types';
import BodyText from "./text/BodyText"
import H4 from "./text/H4"
import { Box } from "@mui/material";
import WarningIcon from '@mui/icons-material/Warning';

const ErrorMsg = ({title, message}) => (
  <Box sx={{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    maxWidth: '600px',
    margin: '0 auto'
  }}>
    <H4 sx={{color: 'error.main', display: 'flex', alignItems: 'center', mb: 1}}>
      <WarningIcon sx={{mr: 1}} /> {title}
    </H4>
    <BodyText sx={{textAlign: 'center'}}>
      {message}
    </BodyText>
  </Box>
);

ErrorMsg.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired
}

export default ErrorMsg;
