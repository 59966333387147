import { ContractDocumentRoles } from '@jsr-law/contracts-api';

export const notices = {
  [ContractDocumentRoles.Owner]: {
    ownerNotCompleted: {
      severity: 'warning',
      title: 'Notice!',
      notice: `Click "Start signing" to begin filling the document. Once you click the yellow "Finish" button you will no longer be able to edit
        any of the fields in this document and a signing request will be sent to the Other Party.
        Make sure all of the info is correct and that any important values (dollar amounts,
        percentages, etc...) have been fully agreed upon with the Other Party. If you leave and
        come back, your progress will be saved.`,
      key: 'ownerNotCompleted'
    },
    ownerCompletedAwaitingClient: {
      severity: 'info',
      title: 'Awaiting Other Party',
      notice: `Waiting for Other Party signature. An email has been sent to them with unique link
        to the document. You can leave and come back later. Progress will be saved.`,
      key: 'ownerCompletedAwaitingClient'
    },
    documentCompletedPaid: {
      severity: 'info',
      title: 'Finished',
      notice: 'This document has been finalized and is available for download.',
      key: 'documentCompletedPaid'
    }
  },
  [ContractDocumentRoles.Client]: {
    clientNotCompletedAndPayment: {
      severity: 'info',
      title: 'Complete The Document Below',
      notice: `Complete the required fields in the document below. Once the document is completed
        and signed, you'll need to pay the document signing fee before it can be finalized and downloaded. Please note that once you click
        the yellow "Finish" button you will no longer be able to edit any of the fields in this document. If you leave and
        come back, your progress will be saved.`,
      key: 'clientNotCompleted'
    },
    clientNotCompletedOwnerPaid: {
      severity: 'info',
      title: 'Complete The Document Below',
      notice: `Complete the required fields in the document below. Once the document is completed
        and signed, you'll be able to download the finished document. Please note that once you click
        the yellow "Finish" button you will no longer be able to edit any of the fields in this document. If you leave and
        come back, your progress will be saved.`,
      key: 'clientNotCompleted'
    },
    documentCompletedPaid: {
      severity: 'info',
      title: 'Finished',
      notice: 'This document has been finalized and is available for download.',
      key: 'documentCompletedPaid'
    }
  }
}
