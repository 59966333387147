import { useEffect, useCallback, useState } from 'react';
import { useFormikContext } from 'formik';
import { useSelector } from 'react-redux';
import StripeConnectDialog from "components/StripeConnectDialog";



const StripeConnectListener = () => {
  const [ dialogOpen, setDialogOpen ] = useState(false);
  const { values, setFieldValue } = useFormikContext();
  const { profile } = useSelector(state => state.currentUser);
  const collectOwnerPayment = values.collectOwnerPayment;
  const stripeConnectChargesEnabled = profile.stripeConnectChargesEnabled;

  const handleClose = () => {
    setFieldValue('collectOwnerPayment', false);
    setDialogOpen(false);
  }

  useEffect(() => {
    if (collectOwnerPayment && !stripeConnectChargesEnabled) {
      setDialogOpen(true);
    }
  }, [collectOwnerPayment, stripeConnectChargesEnabled]);

  if (stripeConnectChargesEnabled) return null;

  return (
    <StripeConnectDialog isOpen={dialogOpen} onClose={handleClose} />
  )
}

export default StripeConnectListener;
