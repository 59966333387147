import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

/**
 * Empty page layout template (no app bar, no sidebar)
 */
export default function EmptyTemplate({children}) {
  return (
    <Box
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        height: '100vh',
        overflow: 'auto'
      }}
    >
      <Container maxWidth={false} sx={{ mt: 4, mb: 4, display: 'flex', flexDirection: 'column' }}>
        {children}
      </Container>
    </Box>
  );
}
