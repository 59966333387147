import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { isSignInWithEmailLink, signInWithEmailLink } from 'firebase/auth';
// 3rd party components
import { Card, CardContent, Box, Typography, Avatar } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
// MC components
import Spinner from 'components/_common/Spinner';
import Button from 'components/_common/Button';
// utils
import { clearLoginEmail } from 'utils/login';
import { firebaseAuth } from 'utils/firebaseUtils';

const queryString = require('query-string');

/**
 * User is directed here when they click the 1-time sign-in link that is
 * emailed to them from the sign-in flow
 */
export default function VerifySignIn() {
  const [errorMessage, setErrorMessage] = useState(null);
  const navigate = useNavigate();
  const params = queryString.parse(window.location.search);

  // verify the sign-in link and log them in...
  useEffect(() => {
    console.info("verifying sign-in link...");
    const urlParamsEmail = params.email ? decodeURIComponent(params.email) : params.email;

    // console.info('urlParamsEmail', urlParamsEmail);

    if (isSignInWithEmailLink(firebaseAuth, window.location.href)) {
      console.info('isSignInWithEmailLink, attempting sign-in...', window.location.href);



      if (urlParamsEmail) {
        setTimeout(function() { // give it a little delay for better UX

          // console.info('firebaseAuth', firebaseAuth);
          // console.info('urlParamsEmail', urlParamsEmail);
          // console.info('window.location.href', window.location.href);

          signInWithEmailLink(firebaseAuth, urlParamsEmail, window.location.href)
            .then(() => clearLoginEmail())
            .catch((e) => {
              // Sentry.captureException(e);

              let message = e.message.includes('FirebaseError')
                ? e.message.split('FirebaseError:')[0]
                : e.message;

              if (e.code === 'auth/network-request-failed') {
                message +=
                  ' If your internet connection is currently active, your network administrator may be blocking requests to googleapis.com/identitytoolkit';
              }

              if (e.code === 'auth/invalid-action-code') {
                message = 'Invalid sign-in link. It most likely expired before you used it. Click "Back" below to get a new sign-in link and try again. If the error persists, contact <a href="mailto:support@musiccontracts.com">support@musiccontracts.com</a>.'
              }

              setErrorMessage(message);
              console.error(e);
            });
        }, 2000);
      }
    }
  }, [params.email]);

  return (
    <Box
      sx={{
        mt: { xs: 8, sm: 16 },
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Card elevation={12}>
        <CardContent sx={{display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center'}}>
          <>
            {errorMessage ? (
              <Box sx={{maxWidth: '500px', margin: '0 auto', textAlign: 'center', mb: 2, mt: 2}}>
                <Box sx={{color: 'error.main'}}>
                  <Typography component="h1" variant="h5" sx={{mb: 2, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <WarningIcon sx={{mr: 1}} /> Sign-In Error
                  </Typography>
                </Box>
                <Typography sx={{mb: 4}} dangerouslySetInnerHTML={{ __html: errorMessage }} />
                <Button
                  onClick={() => {
                    setErrorMessage(null);
                    navigate('/auth/login');
                  }}
                  fullWidth
                >
                  Back
                </Button>
              </Box>
            ) : (
              <>
                <Avatar sx={{ m: 1, mt: 3, bgcolor: 'secondary.main' }}>
                  <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5" sx={{mb: 2}}>
                  Signing In...
                </Typography>
                <Box sx={{ mt: 1, mb: 2, minWidth: '375px', display: 'flex', justifyContent: 'center' }}>
                  <Spinner />
                </Box>
              </>
            )}
          </>
        </CardContent>
      </Card>
    </Box>
  );
}
