import { Typography, Box } from "@mui/material";


export default function StatusBadge({text, color, onClick = () => {}}) {
  return (
    <Box
      sx={{
        display: 'inline-block',
        backgroundColor: color,
        borderRadius: '30px',
        padding: '4px 12px'
      }}
      onClick={onClick}
    >
      <Typography sx={{fontSize: '12px', fontWeight: 500, m: 0, p: 0}}>
        {text}
      </Typography>
    </Box>
  )
}
