import { ContractDocumentRoles } from '@jsr-law/contracts-api';
import useContractsRestAPI from 'hooks/useContractsRestAPI';
import { createContext, useContext, useReducer, useCallback } from 'react';

const initialState = {
  hasError: false,
  contractDocument: null,
  embedIsReady: false,
  viewerRole: null,
  showOwnerFeeCheckout: false,
  stripeConnectAccountId: null
};

function contractDocumentReducer(state, action) {
  switch (action.type) {
    case 'updateContractDocument': {
      return {
        ...state,
        contractDocument: {...action.payload}
      };
    }
    case 'setError': {
      return {
        ...state,
        hasError: action.payload
      }
    }
    case 'setEmbedReady': {
      return {
        ...state,
        embedIsReady: action.payload
      }
    }
    case 'setViewerRole': {
      return {
        ...state,
        viewerRole: action.payload
      }
    }
    case 'setOwnerFeeCheckout': {
      return {
        ...state,
        showOwnerFeeCheckout: action.payload
      }
    }
    case 'setStripeConnectAccountId':
      return {
        ...state,
        stripeConnectAccountId: action.payload
      }
    default: {
      throw Error('Unknown action: ' + action.type);
    }
  }
}

const Context = createContext(null);

export default function ContractDocumentProvider({ children }) {
  const apiClient = useContractsRestAPI();

  const [state, dispatch] = useReducer(
    contractDocumentReducer,
    initialState
  );

  const contractDocumentId = state.contractDocument?.id;

  const openOwnerFeeCheckout = useCallback(async () => {
    const { stripeConnectAccountId } = await
      apiClient.get(
        `/documents/${state.contractDocument.id}/get-owner-stripe-account-id`
      );

    dispatch({
      type: 'setStripeConnectAccountId',
      payload: stripeConnectAccountId
    });

    dispatch({
      type: 'setOwnerFeeCheckout',
      payload: true
    });
  }, [contractDocumentId]);

  const setContractDocument = useCallback(contractDocument => {
    dispatch({
      type: 'updateContractDocument',
      payload: contractDocument
    })
  }, []);

  const setEmbedIsReady = useCallback((isReady = false) => {
    dispatch({
      type: 'setEmbedReady',
      payload: isReady
    })
  }, []);

  /**
   * @param {boolean} hasError
   */
  const setError = useCallback((hasError) => {
    dispatch({
      type: 'setError',
      payload: hasError
    });
  }, []);

  const setViewerRole = useCallback((role) => {
    if (!Object.values(ContractDocumentRoles).includes(role)) {
      throw Error(`${role} is not a valid contract document role`);
    }

    dispatch({
      type: 'setViewerRole',
      payload: role
    })
  }, []);

  const actions = {
    setContractDocument,
    setError,
    setEmbedIsReady,
    setViewerRole,
    openOwnerFeeCheckout
  };

  return (
    <Context.Provider value={[state, actions]}>
      {children}
    </Context.Provider>
  );
}

export function useContractDocument() {
  return useContext(Context);
}
