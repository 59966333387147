import useContractsDataAPI from 'hooks/useContractsDataAPI';
import { Box, Paper } from '@mui/material';
import cx from 'classnames';
import H2 from 'components/_common/text/H2';
import BodyText from 'components/_common/text/BodyText';
import { useContractDocument } from '../ContractDocumentProvider';

import DocumentTitle from 'components/ContractDocument/DocumentTitle';
import Checkout from "components/Checkout/Checkout";
import { STRIPE_DOC_SIGNING_FEE } from "config/stripe";

// imports jsdoc typedefs
/// <reference path="types/contracts-api.js" />
/// <reference path="types/misc.js" />

export default function OwnerPaymentCheckout() {
  const [{contractDocument}, contractDocumentActions] = useContractDocument();
  const { enums } = useContractsDataAPI();
  const { ContractDocumentRoles, ContractPaymentTypes } = enums;

  const ownerInfo = contractDocument?.recipients
    ? contractDocument.recipients.find(rec => rec.role === ContractDocumentRoles.Owner)
    : null;


  return (
    <Box
      className={cx('flex flex-col justify-center flex-1')}
      sx={{ mt: 0, mb: 0 }}
    >
      <Box
        className="flex flex-col items-center justify-center flex-1"
        sx={{ position: 'relative' }}
      >
        <Box
          className="flex flex-col items-center flex-1"
          sx={{
            maxWidth: '800px',
            margin: '5vh auto 0',
          }}
        >
          <Paper elevation={24} sx={{ p: 4 }}>
            <H2 sx={{ mb: 2, textAlign: 'center' }}>Payment</H2>
            <DocumentTitle title={contractDocument.title} />
            <BodyText
              sx={{ maxWidth: '500px', textAlign: 'center', mb: 4 }}
            >
              There is a signing fee of{' '}
              <span style={{ fontWeight: 600 }}>
                ${STRIPE_DOC_SIGNING_FEE}
              </span>{' '}
              that must be paid before the document can be filled and
              signed.
            </BodyText>
            <Checkout
              contractDocumentId={contractDocument.id}
              customerEmail={ownerInfo?.email ?? ''}
              customerName={
                ownerInfo
                  ? `${ownerInfo.first_name} ${ownerInfo.last_name}`
                  : ''
              }
              paymentType={ContractPaymentTypes.SigningFee}
            />
          </Paper>
        </Box>
      </Box>
    </Box>
  );
}
