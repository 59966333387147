import useAppContext from 'hooks/useAppContext';

import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import AppBar from '@mui/material/AppBar';
import ButtonMenu from './ButtonMenu';

import {ReactComponent as Logo} from '../../assets/img/mc-logo-white.svg';

/**
 * Basic layout template with app bar at top
 */
export default function BasicTemplate({children}) {
  const [{appBar: { actionMenu }}] = useAppContext();

  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}
      key="basic-template"
    >
      <AppBar position="fixed">
        <Toolbar
          sx={{
            pr: '24px', // keep right padding when drawer closed
          }}
        >
          {/* <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            // onClick={toggleDrawer}
            sx={{
              // marginRight: '36px',
              display: { xs: 'block', md: 'none' },
              // ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton> */}
          {/* <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            sx={{ flexGrow: 1 }}
          >
            Music Contracts
          </Typography> */}
          <Box sx={{mr: 2, flexGrow: 1, display: 'flex'}}>
            <Box sx={{height: {xs: '30px', md: '38px'}, ml: {xs: 1, md: 0}, display: 'flex', flexShrink: 1}}>
              <Logo style={{width: '100%', height: 'auto'}} />
            </Box>
          </Box>

          {actionMenu.enabled
            ? <ButtonMenu
                size="small"
                text="Actions"
                menuContent={actionMenu.items}
                id="app-bar-action-menu"
                sx={{
                  backgroundColor: 'rgba(255,255,255,.1)',
                  '&:hover': { backgroundColor: 'rgba(255,255,255,.2)' },
                }}
              />
            : <div />
          }
          {/* <IconButton color="inherit">
            <Badge badgeContent={4} color="secondary">
              <NotificationsIcon />
            </Badge>
          </IconButton> */}
        </Toolbar>
      </AppBar>

      {/* Main Content Area */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          // flex: '1',
          // height: '100vh',
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <Toolbar /> {/* Just here for top padding */}
        <Container
          maxWidth={false}
          sx={{
            mt: 2,
            mb: 2,
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            // backgroundImage: `url(${bgImage})`,
            // backgroundRepeat: `no-repeat`,
            // backgroundSize: 'cover',
          }}
        >
          {children}
          {/* <Box sx={{ flexGrow: 1 }} className="basic-main-inner">
            {children}
          </Box> */}
        </Container>
      </Box>
    </Box>
  )
}
