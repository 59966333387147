import { createSlice } from "@reduxjs/toolkit";

// imports jsdoc typedefs
/// <reference path="types/contracts-api.js" />

/**
 * @property {boolean} isLoading
 * @property {Object|null} auth
 * @property {UserDocument} profile
 */
const initialState = {
  isLoading: true,
  auth: null,
  profile: null
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loading(state, action) {
      state.isLoading = action.payload;
    },
    setUser: (state, action) => {
      state.auth = action.payload.authUser;
      state.profile = action.payload.profile;
    },
    clearUser: (state, action) => {
      console.info('clearUser Action');
      return {...initialState, isLoading: false};
    },
    setUserProfile: (state, action) => {
      state.profile = {
        ...state.profile,
        ...action.payload
      }
    },
    setToken: (state, action) => {
      state.auth = {...state.auth, ...action.payload}
    }
  },
});

// Action creators are generated for each case reducer function
export const { setUser, loading, clearUser, setToken, setUserProfile } = authSlice.actions;

export default authSlice.reducer;

export const setAuthUser = (authUser) => (
  async (dispatch, getState, {contractsAPI}) => {
    // console.info('running setAuthUser Thunk...', authUser);
    dispatch(loading(true));

    // console.log('setAuthUser : authUser', authUser);

    try {
      const response = await contractsAPI.models.user.get(authUser.uid);
      dispatch(setUser({ authUser, profile: response }));
    } catch (error) {
      console.error('Error fetching user:', error);
    }
    dispatch(loading(false));
  }
);

// export const setUserProfile = (profile) => (
//   async (dispatch) => {
//     dispatch(authSlice.actions.setUserProfile(profile));
//   }
// );

export const setNewToken = (token) => (
  async (dispatch, getState, {contractsAPI}) => {
    const update = {token};

    if (token.claims && token.claims.roles) {
      update.roles = token.claims.roles;
    }

    if (token.claims && token.claims.clientEmail) {
      update.clientEmail = token.claims.clientEmail;
    }

    dispatch(setToken(update));
  }
);
