import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import Button from './Button';
import { SUCCESS_SNACKBAR_OPTIONS, ERROR_SNACKBAR_OPTIONS } from 'config/notistack';
import { useErrorTracking } from "hooks/useErrorTracking";


const CopyToClipboardButton = ({textToCopy, buttonText}) => {
    const { enqueueSnackbar } = useSnackbar();
    const trackError = useErrorTracking();

    const handleClick = () => {
      navigator.clipboard.writeText(textToCopy)
        .then(() => {
          enqueueSnackbar('Copied', SUCCESS_SNACKBAR_OPTIONS)
        })
        .catch((e) => {
          enqueueSnackbar('Error copying to clipboard', ERROR_SNACKBAR_OPTIONS);
          console.error(e);
          trackError(e);
        })
    }

    return <Button variant="outlined" onClick={handleClick}>{buttonText}</Button>;
};

CopyToClipboardButton.propTypes = {
  textToCopy: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired
}

export default CopyToClipboardButton;
