import { createContext, useReducer, useCallback } from 'react';

// imports jsdoc typedefs
/// <reference path="types/misc.js" />

const initialState = {
  appBar: {
    actionMenu: { // navbar action menu
      enabled: false,
      title: 'Actions',
      items: []
    },
  },
  appOverlaySpinner: false,
  infoModal: {
    isOpen: false,
    title: null,
    body: null
  },
  confirmationModal: {
    isOpen: false,
    title: null,
    body: null,
    onConfirm: () => {},
    onCancel: () => {}
  }
};

function appContextReducer(state, action) {
  switch (action.type) {
    case 'setActionMenu': {
      return {
        ...state,
        appBar: {
          ...state.appBar,
          actionMenu: {
            ...action.payload
          }
        }
      }
    }
    case 'setInfoModal': {
      return {
        ...state,
        infoModal: {
          ...state.infoModal,
          ...action.payload
        }
      }
    }
    case 'setConfirmationModal': {
      return {
        ...state,
        confirmationModal: {
          ...state.confirmationModal,
          ...action.payload
        }
      }
    }
    case 'setOverlaySpinner': {
      return {
        ...state,
        appOverlaySpinner: action.payload
      }
    }
    default: {
      throw Error('Unknown action: ' + action.type);
    }
  }
}

export const AppContext = createContext(null);

export default function AppContextProvider({ children }) {
  const [state, dispatch] = useReducer(
    appContextReducer,
    initialState
  );

  const showAppOverlaySpinner = useCallback(() => {
    dispatch({
      type: 'setOverlaySpinner',
      payload: true
    })
  }, []);

  const hideAppOverlaySpinner = useCallback(() => {
    dispatch({
      type: 'setOverlaySpinner',
      payload: false
    })
  }, []);

  /**
   * @param {ActionMenuData} actionMenu
   */
  const setActionMenu = useCallback((actionMenu) => {
    dispatch({
      type: 'setActionMenu',
      payload: actionMenu
    })
  }, []);

  /**
   * @param {InfoDialogData} infoDialog
   */
  const setInfoModal = useCallback((infoDialog) => {
    dispatch({
      type: 'setInfoModal',
      payload: infoDialog
    })
  }, []);

  const closeInfoModal = useCallback(() => {
    dispatch({
      type: 'setInfoModal',
      payload: {
        isOpen: false
      }
    });

    setTimeout(() => {
      dispatch({
        type: 'setInfoModal',
        payload: {...initialState.infoModal}
      });
    }, 250);
  }, []);

  /**
   * @param {ConfirmationDialogData} confirmationDialog
   */
  const setConfirmationModal = useCallback((confirmationDialog) => {
    dispatch({
      type: 'setConfirmationModal',
      payload: confirmationDialog
    })
  }, []);

  const closeConfirmationModal = useCallback(() => {
    dispatch({
      type: 'setConfirmationModal',
      payload: {
        isOpen: false
      }
    });

    setTimeout(() => {
      dispatch({
        type: 'setConfirmationModal',
        payload: {...initialState.confirmationModal}
      });
    }, 250);
  }, []);

  const actions = {
    setActionMenu,
    setInfoModal,
    closeInfoModal,
    setConfirmationModal,
    closeConfirmationModal,
    showAppOverlaySpinner,
    hideAppOverlaySpinner
  };

  return (
    <AppContext.Provider value={[state, actions]}>
      {children}
    </AppContext.Provider>
  );
}
