import useAppContext from 'hooks/useAppContext';
import ConfirmDialog from './_common/dialogs/ConfirmDialog';

export default function AppConfirmationDialog() {
  const [{confirmationModal}, {closeConfirmationModal}] = useAppContext();

  const {
    isOpen,
    title,
    body,
    onConfirm,
    onCancel
  } = confirmationModal;

  const handleConfirm = () => {
    onConfirm();
    closeConfirmationModal();
  }

  const handleCancel = () => {
    onCancel();
    closeConfirmationModal();
  }

  const textProps = {};

  if (title) {
    textProps.title = title;
  }

  if (body) {
    textProps.body = body;
  }

  return (
    <ConfirmDialog
      // handleClose={closeInfoModal}
      onCancel={handleCancel}
      onConfirm={handleConfirm}
      isOpen={isOpen}
      {...textProps}
    />
  )
}
