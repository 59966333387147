import { PropTypes } from "prop-types";
// import Grid from '@mui/material/Grid';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2

export default function GridContainer(props) {
  const { children, className = '', spacing = 2, ...rest } = props;

  return (
    <Grid container spacing={spacing} {...rest} className={className}>
      {children}
    </Grid>
  );
}

GridContainer.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  spacing: PropTypes.number
};
