import { useState } from 'react';
// import { useNavigate } from "react-router-dom";
// import { isSignInWithEmailLink, signInWithEmailLink } from 'firebase/auth';
import { cloudFunction } from 'utils/firebaseUtils';
import { Card, CardContent, Box, Typography, Avatar } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

import SignInForm from 'components/forms/SignInForm';
import Button from 'components/_common/Button';
import { saveLoginEmail } from 'utils/login';
import { SUPPORT_EMAIL } from 'config/global';
import H1 from 'components/_common/text/H1';
import {ReactComponent as Logo} from '../../assets/img/mc-logo-black.svg';

// const queryString = require('query-string');

export default function SignIn() {
  const [signInError, setSignInError] = useState('');
  const [signInLinkSentTo, setSignInLinkSentTo] = useState(null);

  const initiateSignIn = async (email, setSubmitting) => {
    setSignInError('');

    try {
      await cloudFunction('users-loginWithEmail', {
        email,
        origin: window.location.origin,
        query: window.location.search,
      });

      setSubmitting(false);

      saveLoginEmail(email);
      setSignInLinkSentTo(email);
      // setLoading(false);
    } catch (e) {
      // console.error(e);
      setSubmitting(false);
      // Sentry.captureException(e);

      let message = e.message.includes('FirebaseError')
        ? e.message.split('FirebaseError:')[0]
        : e.message;

      setSignInError(message);

      console.error(message);

      if (e.code === 'auth/network-request-failed') {
        message +=
          ' If your internet connection is currently active, your network administrator may be blocking requests to googleapis.com/identitytoolkit';
      }
    }
  };

  return (
    <Box
      sx={{
        mt: { xs: 8, sm: 16 },
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Box sx={{mb: 2}}>
        {/* <H1>MusicContracts</H1> */}
        <Box sx={{height: {xs: '60px', md: '60px'}, mb: {xs: 2, md: 4}, display: 'flex', flexShrink: 1}}>
          <Logo style={{width: '100%', height: 'auto'}} />
        </Box>
      </Box>
      <Card elevation={12} sx={{maxWidth: '100%'}}>
        <CardContent sx={{display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center'}}>
          {!Boolean(signInLinkSentTo) ? (
            <>
              <Avatar sx={{ m: 1, mt: 3, bgcolor: 'secondary.main' }}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5" sx={{mb: 2}}>
                Request a Sign In Link
              </Typography>
              <Typography
                sx={{maxWidth: '500px', margin: '0 auto', textAlign: 'center', mb: 2}}
              >
                Enter the billing email address you used when you purchased your contract template(s) and you will recieve a magic sign-in link
              </Typography>
              <Box sx={{ mt: 1, minWidth: {xs: '100%', sm: '375px'} }}>
                <SignInForm initiateSignIn={initiateSignIn} />
              </Box>
              {signInError &&
                <Box sx={{ color: 'error.main', mt: 1 }}>
                  <Typography>
                    {signInError}
                  </Typography>
                </Box>
              }
            </>
          ) : (
            <Box sx={{maxWidth: '500px', margin: '0 auto', textAlign: 'center', mb: 2, mt: 2}}>
              <Typography sx={{mb: 4}}>
                A Sign-In Link with additional instructions was emailed to{' '}
                <strong>{signInLinkSentTo}</strong>. Be sure to check your promotions or spam folders.<br /><br />
                If you don't receive the email within a few minutes, contact{' '}
                <a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a> for further instructions.<br /><br />
                <strong>You can close this browser tab.</strong> <br />The sign-in link will open a new one.
              </Typography>
              <Button
                onClick={() => {
                  setSignInLinkSentTo(null);
                }}
                fullWidth
              >
                Back
              </Button>
            </Box>
          )}
         </CardContent>
      </Card>
    </Box>
  );
}
