import { Form, Formik } from "formik";
import { useSelector } from 'react-redux';
import { useContext } from "react";
import { FormContext } from "../CreateDocumentForm";
import * as yup from "yup";

import { Box, InputAdornment, TextField } from "@mui/material";
import CustomFormikField from 'components/_common/formik/CustomFormikField';
import RadioBool from 'components/_common/formik/fields/RadioBool';
import SubmitButton from "components/_common/formik/SubmitButton";
import H4 from 'components/_common/text/H4';
import BodyText from "components/_common/text/BodyText";
import Button from "components/_common/Button";
import useContractsDataAPI from "hooks/useContractsDataAPI";
import GridContainer from 'components/_common/GridContainer';
import GridItem from 'components/_common/GridItem';
import DocumentTitle from "components/ContractDocument/DocumentTitle";
import { STRIPE_DOC_SIGNING_FEE } from "config/stripe";
import StripeConnectListener from "../StripeConnectListener";

// imports jsdoc typedefs
/// <reference path="types/contracts-api.js" />

function UserStep() {
  const { models, enums } = useContractsDataAPI();
  const { ContractDocumentRoles } = enums;
  const { profile } = useSelector(state => state.currentUser);

  const {
    activeStepIndex,
    setActiveStepIndex,
    formData,
    setFormData,
    contractTemplate
  } = useContext(FormContext);

  const formFields = {
    email: 'email',
    first_name: 'first_name',
    last_name: 'last_name',
    collectOwnerPayment: models.contractDocument.fields.collectOwnerPayment,
    ownerPaymentAmount: models.contractDocument.fields.ownerPaymentAmount,
    ownerPaySigningFee: models.contractDocument.fields.ownerPaySigningFee
  }

  const schemaDef = models.contractDocument.schemaDef;

  return (
    <Formik
      initialValues={{
        [formFields.email]: profile.email, // pandadoc field
        [formFields.first_name]: profile.firstName, // pandadoc field
        [formFields.last_name]: profile.lastName, // pandadoc field
        [formFields.collectOwnerPayment]: formData.collectOwnerPayment || false,
        [formFields.ownerPaymentAmount]: formData.ownerPaymentAmount || 0.00,
        [formFields.ownerPaySigningFee]: formData.ownerPaySigningFee || false
      }}
      validationSchema={yup.object().shape({
        [formFields.email]: yup.string().email('Please enter a valid email address').required('required'),
        [formFields.first_name]: yup.string().required('required'),
        [formFields.last_name]: yup.string().required('required'),
        [formFields.collectOwnerPayment]: schemaDef[formFields.collectOwnerPayment],
        [formFields.ownerPaymentAmount]: schemaDef[formFields.ownerPaymentAmount],
        [formFields.ownerPaySigningFee]: schemaDef[formFields.ownerPaySigningFee]
      })}
      onSubmit={(values) => {
        const data = {
          ...formData,
          userId: profile.id,
          [formFields.collectOwnerPayment]: values[formFields.collectOwnerPayment],
          [formFields.ownerPaymentAmount]: values[formFields.ownerPaymentAmount],
          [formFields.ownerPaySigningFee]: values[formFields.ownerPaySigningFee],
          recipients: [
            {
              email: values.email,
              first_name: values.first_name,
              last_name: values.last_name,
              role: ContractDocumentRoles.Owner
            }
          ],
          recipientEmails: [values.email]
        };
        setFormData(data);
        setActiveStepIndex(activeStepIndex + 1);
      }}
    >
      {({ values, isSubmitting }) => (
        <Form>
          <Box sx={{maxWidth: '600px'}}>
            <Box className="text-center"  sx={{mb: 6}}>
              <H4 sx={{mb: 1, mx: 'auto'}}>Create Document from Template</H4>
              <DocumentTitle title={contractTemplate.title} maxWidth="100%" />
              {/* <Subtitle>
                Enter your info
              </Subtitle> */}
            </Box>
            <Box sx={{mb: 2}}>
              <BodyText className="text-xl font-medium" sx={{mb: 2}}>
                Your Info
              </BodyText>
            </Box>
            <GridContainer>
              <GridItem xs={12}>
                <CustomFormikField
                  name={formFields.email}
                  label="Email"
                  CustomComponent={TextField}
                  margin="normal"
                  fullWidth
                  id={formFields.email}
                  required
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} md={6}>
                <CustomFormikField
                  name={formFields.first_name}
                  label="First Name"
                  CustomComponent={TextField}
                  margin="normal"
                  fullWidth
                  id={formFields.first_name}
                  required
                />
              </GridItem>
              <GridItem xs={12} md={6}>
                <CustomFormikField
                  name={formFields.last_name}
                  label="Last Name"
                  CustomComponent={TextField}
                  margin="normal"
                  fullWidth
                  id={formFields.last_name}
                  required
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12}>
                <CustomFormikField
                  name={formFields.ownerPaySigningFee}
                  label={`Who should pay the $${STRIPE_DOC_SIGNING_FEE} document signing fee?`}
                  CustomComponent={RadioBool}
                  trueLabel="I will pay"
                  falseLabel="The other party will pay"
                  margin="normal"
                  fullWidth
                  id={formFields.ownerPaySigningFee}
                  required
                  mt={2}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12}>
                <CustomFormikField
                  name={formFields.collectOwnerPayment}
                  label="Would you like to request a credit card payment from the Other Pary upon completion?"
                  CustomComponent={RadioBool}
                  margin="normal"
                  fullWidth
                  id={formFields.collectOwnerPayment}
                  required
                  mt={2}
                />
              </GridItem>
            </GridContainer>
            {values[formFields.collectOwnerPayment] &&
              <GridItem xs={12}>
                <CustomFormikField
                  name={formFields.ownerPaymentAmount}
                  type="number"
                  label="Amount (USD)"
                  CustomComponent={TextField}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                  margin="normal"
                  fullWidth
                  id={formFields.ownerPaymentAmount}
                  required
                />
              </GridItem>
            }
            <Box className="flex justify-between" sx={{mt: 6}}>
              <Button
                onClick={() => setActiveStepIndex(activeStepIndex - 1)}
              >
                Back
              </Button>
              <SubmitButton
                type="submit"
              >
                Next
              </SubmitButton>
            </Box>
          </Box>
          <StripeConnectListener />
        </Form>
      )}
    </Formik>
  );
}

export default UserStep;
