import { useErrorTracking } from "hooks/useErrorTracking";
import { useParams } from 'react-router-dom';
import useContractsDataAPI from 'hooks/useContractsDataAPI';
import { Box, Paper } from '@mui/material';
import cx from 'classnames';
import H2 from 'components/_common/text/H2';
import BodyText from 'components/_common/text/BodyText';
import Checkout from 'components/Checkout/Checkout';
import { useContractDocument } from '../ContractDocumentProvider';

import PandaDocEmbed from 'components/PandaDocEmbed';
import Spinner from 'components/_common/Spinner';
import Subtitle from 'components/_common/text/Subtitle';
import { SUPPORT_EMAIL } from 'config/global';
import DocumentTitle from 'components/ContractDocument/DocumentTitle';
import { STRIPE_DOC_SIGNING_FEE } from 'config/stripe';
import { useDeepCompareEffect } from 'react-use';
import { useState } from "react";

// imports jsdoc typedefs
/// <reference path="types/contracts-api.js" />

export default function ClientContractDocument() {
  const [{
    contractDocument,
    hasError,
    embedIsReady,
    showOwnerFeeCheckout,
  }, contractDocumentActions] = useContractDocument();
  const { documentId } = useParams();
  const { models, enums } = useContractsDataAPI();
  const { ContractDocumentStatus, ContractDocumentRoles, ContractPaymentTypes } = enums;
  const trackError = useErrorTracking();

  // useDeepCompareEffect(() => {
  //   if (
  //     ContractDocumentStatus.Completed === contractDocument.documentStatus
  //     && contractDocument.collectOwnerPayment
  //     && !contractDocument.ownerFeeStripePaymentId
  //   ) {

  //   }
  // }, [
  //   ContractDocumentStatus,
  //   contractDocument,
  //   isOpenOwnerFeeDialog
  // ]);

  /**
   * Set the viewer role in the ContractDocument Context
   */
  useDeepCompareEffect(() => {
    contractDocumentActions.setViewerRole(ContractDocumentRoles.Client);
  }, [contractDocumentActions, ContractDocumentRoles]);

  /**
   * Firestore listener on contractDocument
   */
  useDeepCompareEffect(() => {
    const unsubscribe = models.contractDocument.listenOne(
      documentId,
      (data) => {
        // console.log('contractDocument update', data);
        contractDocumentActions.setContractDocument(data)
      },
      (error) => {
        // if (error.message === 'not found') {
        //   return navigate('/404')
        // }
        trackError(error);
        console.error(error);
        contractDocumentActions.setError(true);
      }
    );

    return unsubscribe;
  }, [models, contractDocumentActions, trackError]);

  const clientInfo = contractDocument?.recipients
    ? contractDocument.recipients.find(rec => rec.role === ContractDocumentRoles.Client)
    : null;

  const ownerInfo = contractDocument?.recipients
    ? contractDocument.recipients.find(rec => rec.role === ContractDocumentRoles.Owner)
    : null;

  const ownerPaysFee = Boolean(contractDocument?.ownerPaySigningFee);
  const hasOwnerFee = Boolean(contractDocument?.collectOwnerPayment)
  const signingFeePaid = Boolean(contractDocument?.signingFeeStripePaymentId);
  const ownerFeePaid = Boolean(contractDocument?.ownerFeeStripePaymentId);


  // const docCreating = contractDocument?.documentStatus === ContractDocumentStatus.Creating;
  const docReady = contractDocument?.documentStatus === ContractDocumentStatus.Ready;
  const docSent = contractDocument?.documentStatus === ContractDocumentStatus.Sent;
  const docCompleted = contractDocument?.documentStatus === ContractDocumentStatus.Completed;
  const docPaid = contractDocument?.documentStatus === ContractDocumentStatus.Completed && signingFeePaid;
  const docError = contractDocument?.documentStatus === ContractDocumentStatus.Error;

  const awaitingOwnerSig = docReady;
  const clientReady = docSent || docCompleted || docPaid || docError;
  const renderPandadocEmbed = docSent || docPaid;
  const awaitingClientSigningFeePayment = docCompleted && !signingFeePaid && !ownerPaysFee;
  const awaitingOwnerFeePayment = docCompleted && hasOwnerFee && !ownerFeePaid;
  const awaitingOwnerSigningFeePayment = docCompleted && !signingFeePaid && ownerPaysFee;
  const awaitingClientPayment = awaitingOwnerFeePayment || awaitingClientSigningFeePayment;

  const awaitingPayment = awaitingOwnerFeePayment || awaitingClientPayment;

  let paymentType = ContractPaymentTypes.SigningFee;

  if (awaitingOwnerFeePayment && awaitingClientSigningFeePayment) {
    paymentType = ContractPaymentTypes.SigningAndOwnerFee;
  }

  if (awaitingOwnerFeePayment && ownerPaysFee) {
    paymentType = ContractPaymentTypes.OwnerFee;
  }

  const checkoutMessage = () => {
    if (paymentType === ContractPaymentTypes.SigningFee) {
      return `There is a signing fee of <span style="font-weight: 600">$${STRIPE_DOC_SIGNING_FEE}</span> that must be paid
        before the document can be finalized. After payment you will
        have the opportunity to download a PDF of the finished document.`;
    }

    if (paymentType === ContractPaymentTypes.OwnerFee) {
      return `This contract has an assoicated fee of <span style="font-weight: 600">$${contractDocument.ownerPaymentAmount}</span> payable
        to ${ownerInfo.first_name} ${ownerInfo.last_name} and they they have requested that you pay the fee here via credit or debit card.`;
    }

    if (paymentType === ContractPaymentTypes.SigningAndOwnerFee) {
      return `This contract has an assoicated fee of $${contractDocument.ownerPaymentAmount} payable
        to ${ownerInfo.first_name} ${ownerInfo.last_name} and they they have requested that you pay the fee here via credit
        or debit card. There is also a $${STRIPE_DOC_SIGNING_FEE} document signing fee. The
        total amount due is <span style="font-weight: 600">$${Number(contractDocument.ownerPaymentAmount) + Number(STRIPE_DOC_SIGNING_FEE)}</span>. After
        payment you will have the opportunity to download a PDF of the finished document.`;
    }
  }

  const getLoadingMessage = () => {
    if (!contractDocument) return 'Loading';

    if (contractDocument) {
      if (awaitingOwnerSig) {
        return 'Awaiting Other Party Signature'
      }

      if (clientReady && !embedIsReady) {
        return 'Loading';
      }

      return 'Loading';
    }
  }

  let isLoading =
    ((renderPandadocEmbed && !embedIsReady) || !contractDocument)
    || !clientReady;

  // TODO: handle 404

  return (
    <Box className="client-contract-document flex flex-col flex-1">
      {hasError &&
        <Box
          className="flex flex-col items-center flex-1"
          sx={{
            maxWidth: '800px',
            margin: '20vh auto 0',
          }}
        >
          <Paper elevation={24} sx={{p: 4}}>
            <H2 sx={{mb: 2, textAlign: 'center'}}>Uh oh!</H2>
            <BodyText sx={{textAlign: 'center', fontSize: '18px'}}>
              An error occurred while try to fetch the document.<br/>
              Refresh the page to try again. If you still see this message, contact us
              at <a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a> for help.
            </BodyText>
          </Paper>
        </Box>
      }
      {(isLoading && !hasError) &&
        <Box
          className="flex flex-colum justify-center flex-1"
          sx={{mt: 2}}
        >
          <Box
            className="flex flex-col justify-center items-center flex-1"
            sx={{position: 'relative'}}
          >
            <Subtitle className="font-medium" sx={{mb: 1}}>
              {getLoadingMessage()}
            </Subtitle>
            <Spinner />
          </Box>
        </Box>
      }
      {(!hasError && contractDocument && clientReady) &&
        <Box
          className={cx("flex flex-colum justify-center flex-1", {
            hidden: renderPandadocEmbed && !embedIsReady,
            flex: (renderPandadocEmbed && embedIsReady) || awaitingClientPayment
          })}
          sx={{mt: 0, mb: 0}}
        >
          <Box
            className="flex justify-center flex-1"
            sx={{position: 'relative'}}
          >
            {awaitingPayment &&
              <>
                {awaitingOwnerSigningFeePayment && (
                  <Box
                    className="flex flex-col items-center flex-1"
                    sx={{
                      maxWidth: '600px',
                      margin: '20vh auto 0',
                    }}
                  >
                    <Paper elevation={24} sx={{ p: 4 }} className="text-center">
                      <H2 sx={{ mb: 2, textAlign: 'center' }}>
                        Awaiting Signing Fee Payment
                      </H2>
                      <DocumentTitle title={contractDocument.title} />
                      <BodyText
                        sx={{ textAlign: 'center', fontSize: '18px', mb: 2 }}
                      >
                        {`The other party has elected to pay the ${STRIPE_DOC_SIGNING_FEE} signing
                        fee but has not yet made the payment. Once the signing fee has been paid
                        this page will update and you will be able to download the
                        signed/finished document. You can leave and come back. Progress will be saved.`}
                      </BodyText>
                    </Paper>
                  </Box>
                )}
                {awaitingClientPayment && (
                  <Box
                    className="flex flex-col items-center flex-1"
                    sx={{
                      maxWidth: '800px',
                      margin: '10vh auto 0',
                    }}
                  >
                    <Paper elevation={24} sx={{p: 4}}>
                      <H2 sx={{mb: 2, textAlign: 'center'}}>Payment</H2>
                      <DocumentTitle title={contractDocument.title} />
                      <BodyText
                        sx={{maxWidth: '500px', textAlign: 'center', mb: 4}}
                        dangerouslySetInnerHTML={{__html: checkoutMessage()}}
                      />
                      <Checkout
                        contractDocumentId={contractDocument.id}
                        customerEmail={clientInfo?.email ?? ''}
                        customerName={clientInfo ? `${clientInfo.first_name} ${clientInfo.last_name}` : ''}
                        paymentType={paymentType}
                      />
                    </Paper>
                  </Box>
                )}
              </>
            }
            {!awaitingPayment && <PandaDocEmbed />}
          </Box>
        </Box>
      }
    </Box>
  );
}



{/* <Box
  className="flex flex-col items-center flex-1"
  sx={{
    maxWidth: '800px',
    margin: '10vh auto 0',
  }}
>
  <Paper elevation={24} sx={{p: 4}}>
    <H2 sx={{mb: 2, textAlign: 'center'}}>Payment</H2>
    <DocumentTitle title={contractDocument.title} />
    <BodyText sx={{maxWidth: '500px', textAlign: 'center', mb: 4, fontSize: '18px', fontWeight: 500}}>
      Pay {`${ownerInfo.first_name} ${ownerInfo.last_name} $${contractDocument.ownerPaymentAmount}`}
    </BodyText>
    <Checkout
      contractDocumentId={contractDocument.id}
      customerEmail={clientInfo?.email ?? ''}
      customerName={clientInfo ? `${clientInfo.first_name} ${clientInfo.last_name}` : ''}
      paymentType={ContractPaymentTypes.OwnerFee}
      stripeConnectAccountId={stripeConnectAccountId}
    />
  </Paper>
</Box> */}
