import { useNavigate } from 'react-router-dom';
import { useDeepCompareEffect } from 'react-use';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useErrorTracking } from "hooks/useErrorTracking";
import { useSnackbar } from 'notistack';
import useAppContext from 'hooks/useAppContext';
import useContractsDataAPI from 'hooks/useContractsDataAPI';
// 3rd Party Components
import { IconButton, Box } from "@mui/material";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
// Our Components
import GridContainer from 'components/_common/GridContainer';
import GridItem from 'components/_common/GridItem';
import BodyText from 'components/_common/text/BodyText';
import DashboardHeading from 'components/_common/text/DashboardHeading';
import DataTable from 'components/_common/DataTable/DataTable';
import ButtonMenu from 'components/_common/ButtonMenu';


import { SUCCESS_SNACKBAR_OPTIONS, ERROR_SNACKBAR_OPTIONS } from 'config/notistack';
import { renderHtmlString } from 'utils/renderHtmlString';


/**
 * View where users can see their purchased Contract Templates
 */
export default function UserContractTemplates() {
  const [{infoModal}, {setInfoModal}] = useAppContext(); // eslint-disable-line
  const { models } = useContractsDataAPI();
  const fields = models.contractTemplate.fields;
  const currentUser = useSelector(state => state.currentUser);
  const userId = currentUser?.profile?.id;
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  let contractTemplates = [];
  const trackError = useErrorTracking();

  const { isLoading, isSuccess, isError, error, data } = useQuery('userContractTemplates',
    () => models.user.getContractTemplates(userId)
  );

  useDeepCompareEffect(() => {
    if (isError) {
      console.error(error);
      trackError(error);
    }
  }, [isError, error]);

  if (isSuccess) {
    contractTemplates = [...data];
  }

  const copyPdfLinkToClipboard = (url) => {
    navigator.clipboard.writeText(url)
      .then(() => {
        enqueueSnackbar('Copied', SUCCESS_SNACKBAR_OPTIONS)
      })
      .catch((e) => {
        enqueueSnackbar('Error copying to clipboard', ERROR_SNACKBAR_OPTIONS);
        console.error(e);
        trackError(e);
      })
  }

  const actionMenuContent = (rowData) => ([
    {
      type: 'menuItem',
      text: 'Create Document from Template',
      onClick: () => { navigate(`/t/${rowData.id}`) },
      key: `${rowData.id}-view-template`,
      icon: null
    },
    {
      type: 'menuItem',
      onClick: () => copyPdfLinkToClipboard(rowData.pdfUrl),
      key: `${rowData.id}-copy-link`,
      text: 'Copy PDF Link',
      icon: null
    }
  ]);

  const renderActionsButton = (params) => {
    return (
      <ButtonMenu size="small" text="Actions" menuContent={actionMenuContent(params.row)} id={params.id} />
    )
  }

  const renderTemplateTitle = (params) => (
    <Box sx={{display: 'flex', alignItems: 'center'}}>
      <span>
        {params.row.shortTitle}
      </span>
      <IconButton
        sx={{fontSize: '16px'}}
        aria-label="view template description"
        size="small"
        onClick={() => {
          setInfoModal({
            isOpen: true,
            title: params.row.shortTitle,
            body: <div dangerouslySetInnerHTML={renderHtmlString(params.row.description)} />
          });
        }}
      >
        <HelpOutlineIcon fontSize="inherit" />
      </IconButton>
    </Box>
  );

  const getTemplateTitle = ({row}) => row.shortTitle;

  // https://mui.com/x/react-data-grid/column-definition/
  const columns = [
    {
      field: models.contractTemplate.fields.shortTitle,
      headerName: 'Template',
      valueGetter: getTemplateTitle,
      renderCell: renderTemplateTitle,
      flex: 1,
    },
    {
      field: 'actions',
      headerName: '',
      renderCell: renderActionsButton,
      width: 125,
    },
  ];

  // const handleRowClick = (
  //   params, // GridRowParams
  //   event, // MuiEvent<React.MouseEvent<HTMLElement>>
  //   details, // GridCallbackDetails
  // ) => {
  //   console.log('params', params);
  //   console.log('event', event);
  //   console.log('details', details);
  // }

  return (
    <Box>
      <GridContainer sx={{mb: 3}}>
        <GridItem>
          <DashboardHeading text="My Templates" />
          <BodyText>
            Here you'll find any contract templates that you have purchased at musiccontracts.com that are supported by our e-sign service
          </BodyText>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12}>
          <DataTable
            rows={contractTemplates}
            isLoading={isLoading}
            isError={isError}
            // handleRowClick={handleRowClick}
            columns={columns}
            disableColumnMenu
            textSearchFields={[fields.shortTitle]}
            textSearchLabel="Search Template Titles"
            noRowsLabel="No Templates Found"
          />
        </GridItem>
      </GridContainer>
    </Box>
  );
}
