// Step.js
import React, { useContext } from "react";
import { FormContext } from "./CreateDocumentForm";
import Terms from './Steps/TermsStep';
import Owner from './Steps/OwnerStep';
import Client from './Steps/ClientStep';

function FormStepper() {
  const { activeStepIndex } = useContext(FormContext);
  let stepContent;

  switch (activeStepIndex) {
    case 0:
      stepContent = <Terms />;
      break;
    case 1:
      stepContent = <Owner />;
      break;
    case 2:
      stepContent = <Client />;
      break;
    default:
      stepContent = <div />;
      break;
  }

  return stepContent;
}

export default FormStepper;
