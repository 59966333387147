import useAppContext from 'hooks/useAppContext';
import InfoDialog from './_common/dialogs/InfoDialog';

export default function AppInfoDialog() {
  const [{infoModal}, {closeInfoModal}] = useAppContext();

  const {
    isOpen,
    title,
    body
  } = infoModal;

  return (
    <InfoDialog
      handleClose={closeInfoModal}
      isOpen={isOpen}
      title={title}
    >
      {body}
    </InfoDialog>
  )
}
