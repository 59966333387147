import DashboardTemplate from 'components/_common/DashboardTemplate';
import { useSelector } from 'react-redux';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import UserContractTemplates from 'views/UserDashboard/UserContractTemplates';
import UserDocuments from 'views/UserDashboard/UserDocuments';
import UserSettings from 'views/UserDashboard/UserSettings';

export default function UserDashboardLayout() {
  const userRoles = useSelector(state => state.currentUser.profile.roles);
  const navigate = useNavigate();
  // const { profile } = useSelector(state => state.currentUser.profile);

  const navItems = {
    primary: [
      {
        name: 'Templates',
        route: '/u/templates'
      },
      {
        name: 'Documents',
        route: '/u/documents'
      }
    ],
    secondary: [],
    bottom: []
  }

  if (userRoles.includes('admin')) {
    navItems.bottom.push({
      name: 'Admin Dashboard',
      route: '/admin'
    });
  }

  const getUserMenuContent = () => {
    /**@type {ButtonMenuItem[]} */
    const userMenuItems = [
      {
        key: 'settings',
        text: 'Settings',
        onClick: () => navigate('/u/settings')
      }
    ];

    // if (profile.stripeConnectChargesEnabled) {
    //   userMenuItems.push({
    //     key: 'stripe-dashboard',
    //     text: 'Stripe Dashboard',
    //     onClick: () => navigate('/auth/logout')
    //   })
    // }

    userMenuItems.push({
      key: 'signOut',
      text: 'Sign Out',
      onClick: () => navigate('/auth/logout')
    });

    return userMenuItems;
  };

  return (
    <DashboardTemplate navItems={navItems} userMenuContent={getUserMenuContent()}>
      <Routes>
        <Route path="/templates" element={<UserContractTemplates />} />
        <Route path="/documents" element={<UserDocuments />} />
        <Route path="/settings" element={<UserSettings />} />
        <Route path="/*" element={<Navigate to="/u/templates" />} />
      </Routes>
    </DashboardTemplate>
  );
}


