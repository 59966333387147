import { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { TextField, Box, Typography } from '@mui/material';
import CustomFormikField from 'components/_common/formik/CustomFormikField';
import SubmitButton from 'components/_common/formik/SubmitButton';
import { PRIVACY_POLICY_LINK } from 'config/global';

// import ButtonSpinner from 'components/_common/ButtonSpinner';
// import { PRIVACY_POLICY_LINK } from 'constants/general';

// import './index.css';

// import useContractsDataAPI from 'hooks/useContractsDataAPI';

const queryString = require('query-string');

const SignInForm = ({ initiateSignIn }) => {
  const urlParams = queryString.parse(window.location.search);
  const email = urlParams.loginEmail ? decodeURIComponent(urlParams.loginEmail) : '';
  const submitRef = useRef();
  // const { models } = useContractsDataAPI();

  useEffect(() => {
    if (email) {
      submitRef.current.click();
    }
  }, [email]);

  const handleSubmit = async (values, {setSubmitting}) => {
    return initiateSignIn(values.email, setSubmitting);
  }

  return (
    <Formik
      initialValues={{ email }}
      validationSchema={Yup.object().shape({
        email: Yup.string().email('Please enter a valid email').required(),
      })}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting }) => (
        <Form>
          <Box>
            <CustomFormikField
              name="email"
              label="Email Address"
              CustomComponent={TextField}
              margin="normal"
              fullWidth
              id="email"
            />
          </Box>
          <Box>
            <SubmitButton
              ref={submitRef}
              type="submit"
              submitting={isSubmitting}
              fullWidth
            >
              Request Sign-In Link
            </SubmitButton>
          </Box>
          <Box sx={{mt: 1, mb: 2, textAlign: 'center'}}>
            <Typography sx={{fontSize: 12}}>
              By Signing In you agree to our{' '}
              <a href={PRIVACY_POLICY_LINK} target="_blank" rel="noreferrer">
                Privacy Policy
              </a>
            </Typography>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

SignInForm.propTypes = {
  submitting: PropTypes.bool,
  initiateSignIn: PropTypes.func.isRequired,
};

export default SignInForm;
