import PropTypes from 'prop-types';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2

export default function GridItem(props) {
  const { children, className = '', ...rest } = props;
  return (
    <Grid {...rest} className={className}>
      {children}
    </Grid>
  );
}

GridItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
}
