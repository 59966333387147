import Spinner from 'components/_common/Spinner';
import { Box } from '@mui/material';

const Loading = ({delay}) => (
  <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
    {/* <h3>Loading</h3> */}
    <Spinner />
  </Box>
);

export default Loading;
