import { Box } from '@mui/system';
import EmptyTemplate from 'components/_common/EmptyTemplate';
import H1 from 'components/_common/text/H1';
import BodyText from 'components/_common/text/BodyText';

export default function NotFound() {
  return (
    <EmptyTemplate>
      <Box
        sx={{
          marginTop: '25vh',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <H1 sx={{mb: 2}}>Uh oh! Looks like you're lost</H1>
        <BodyText>
          The page you were looking for couldn't be found
        </BodyText>
      </Box>
    </EmptyTemplate>
  );
}
