import { useSelector } from "react-redux";

/**
 * Ensures auth has been checked before loading the app.
 * Only use this once. It is intended to wrap the App component
 */
export default function AuthIsLoaded({children}) {
  const { isLoading } = useSelector(state => state.currentUser);

  if (isLoading) return null;

  return children;
}
