import { useState } from 'react';
import PropTypes from 'prop-types';
import { DataGrid } from '@mui/x-data-grid';
import { TextField, useMediaQuery } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import Loading from '../Loading';
import ErrorMsg from '../ErrorMsg';
import { Card, CardContent, Box, IconButton } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import BodyText from '../text/BodyText';
import { useDeepCompareEffect } from 'react-use';

function DataTable({
  rows,
  columns,
  isLoading,
  isError,
  textSearchFields=[],
  textSearchLabel='Search',
  noRowsLabel='No Items to Display',
  ...otherProps
}) {
  const [expandedRows, setExpandedRows] = useState({});
  const [searchText, setSearchText] = useState('');
  const [filteredRows, setFilteredRows] = useState([]);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));
  // const [rows, setRows] = useState([]);
  // const [columns, setColumns] = useState([]);

  // const rows = [
  //   { id: 1, col1: 'Hello', col2: 'World' },
  //   { id: 2, col1: 'DataGridPro', col2: 'is Awesome' },
  //   { id: 3, col1: 'MUI', col2: 'is Amazing' },
  // ];

  // const columns = [
  //   { field: 'col1', headerName: 'Column 1', width: 150 },
  //   { field: 'col2', headerName: 'Column 2', width: 150 },
  // ];

  // console.log("DataTable : rows", rows);
  // console.log('DataTable : columns', columns);

  // const renderExpand = () => {

  // }

  useDeepCompareEffect(() => {
    if (searchText && searchText.length > 3) {
      setFilteredRows(rows.filter(row => {
        return textSearchFields.some(searchField => {
          const valueGetter = columns.find(c => c.field === searchField)?.valueGetter;

          return valueGetter
            ? valueGetter({row})?.toLowerCase().includes(searchText.toLowerCase())
            : row[searchField]?.toLowerCase().includes(searchText.toLowerCase());
        });
      }))
    } else {
      setFilteredRows([]);
    }
  }, [searchText, textSearchFields]);

  // console.log('searchText', searchText)
  // console.log('filteredRows', filteredRows)

  const getDefaultItemValue = (row, field) => row[field];

  const renderExpandedMobileRow = (params) => {
    const items = columns.filter((col, idx) => idx > 0);

    return (
      <>
        {items.map((item, idx) => (
          <Box className="flex items-center" sx={{my: 2}} key={`${params.id}-${item.field}`}>
            {item.headerName &&
              <BodyText sx={{fontWeight: 500, mr: 1}}>
                {item.headerName}:
              </BodyText>
            }
            {item.renderCell
              ? item.renderCell(params)
              : item.valueGetter
                ? item.valueGetter(params)
                : getDefaultItemValue(params.row, item.field)

            }
          </Box>
        ))}
      </>
    )
  }

  const renderMobileRow = (params) => {
    const row = params.row;

    const handleExpandClick = () => {
      setExpandedRows(state => {
        if (state[params.id]) {
          return {...state, [params.id]: false};
        } else {
          return {...state, [params.id]: true};
        }
      })
    }

    return (
      <Box>
        <Box sx={{display: 'flex', alignItems: 'start'}}>
          <IconButton size="small" onClick={handleExpandClick} sx={{mr: 2}}>
            {expandedRows[params.id] ? <RemoveCircleOutlineIcon /> : <AddCircleOutlineIcon />}
          </IconButton>
          <Box>
            {columns[0].renderCell ? columns[0].renderCell(params) : row[columns[0].field]}
            <Box sx={{display: expandedRows[params.id] ? 'block' : 'none'}}>
              {renderExpandedMobileRow(params)}
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }

  const handleSearchTextChange = (e) => {
    setSearchText(e.target.value);
  };

  const mobileColumns = [
    { field: 'mobile-row', headerName: columns[0].headerName, renderCell: renderMobileRow, flex: 1 }
  ];

  let renderColumns = isSmallScreen
    ? mobileColumns
    : columns;

  return (
    <Box>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {isError ? (
            <Box sx={{display: 'flex', justifyContent: 'center', mt: 4}}>
              <Card>
                <CardContent>
                  <ErrorMsg
                    title="Error Loading Data"
                    message="An error occurred while trying to load data from the server. Try refreshing your browser. If the error persists, please contact us."
                  />
                </CardContent>
              </Card>
            </Box>
          ) : (
            <Box>
              <Box sx={{display: `${textSearchFields.length ? 'flex' : 'none'}`, justifyContent: 'flex-end'}}>
                <TextField
                  sx={{
                    background: 'white',
                    mb: 2,
                    minWidth: isSmallScreen ? '100%' : isMediumScreen ? '50%' : '33%'
                  }}
                  size="small"
                  label={textSearchLabel}
                  value={searchText}
                  onChange={handleSearchTextChange}
                />
              </Box>
              <DataGrid
                // onRowClick={handleRowClick}
                // components={{
                //   Toolbar: SearchToolbar,
                // }}
                sx={{
                  boxShadow: 2,
                  background: 'white',
                  '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '8px' },
                  '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: '15px' },
                  '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { py: '22px' },
                }}
                disableSelectionOnClick
                autoHeight
                pageSize={isSmallScreen ? 5 : 20}
                rows={searchText.length > 3 ? filteredRows : rows}
                columns={renderColumns}
                getRowHeight={() => 'auto'}
                localeText={{
                  noRowsLabel: textSearchFields.length && searchText.length > 3
                    ? `${textSearchLabel}: nothing found containing '${searchText}'`
                    : noRowsLabel
                }}
                {...otherProps}
              />
            </Box>
          )}
        </>
      )}
    </Box>
  );
}

DataTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  isError: PropTypes.bool,
  isLoading: PropTypes.bool,
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  textSearchFields: PropTypes.arrayOf(PropTypes.string),
  textSearchLabel: PropTypes.string,
  noRowsLabel: PropTypes.string
}

export default DataTable;
