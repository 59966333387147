import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import EmptyTemplate from 'components/_common/EmptyTemplate';
import SignIn from 'views/Auth/SignIn';
import SignOut from 'views/Auth/SignOut';
import Verify from 'views/Auth/Verify';
// import { useDeepCompareEffect } from 'react-use';
// import { current } from '@reduxjs/toolkit';

const queryString = require('query-string');

export default function AuthLayout() {
  const navigate = useNavigate();
  const location = useLocation();
  const currentUser = useSelector(state => state.currentUser);
  const urlParams = queryString.parse(window.location.search);
  const hasAuthUser = Boolean(currentUser.auth);
  const authUserIsAnonymous = currentUser.auth?.isAnonymous;

  useEffect(() => {
    if (location.pathname !== '/auth/logout') {
      if (hasAuthUser && !authUserIsAnonymous) {
        if (urlParams.redirectTo) {
          // redirect to url the user was originally trying to visit
          console.info(`AuthLayout : redirecting to ${urlParams.redirectTo}`);
          navigate(decodeURIComponent(urlParams.redirectTo));
        } else {
          console.info('AuthLayout : redirecting to /u/templates');
          navigate('/u/templates');
        }
      }
    }
  }, [hasAuthUser, authUserIsAnonymous, urlParams.redirectTo, location.pathname, navigate]);

  return (
    <EmptyTemplate>
      <Routes>
        <Route path="/login" element={<SignIn />} />
        <Route path="/logout" element={<SignOut />} />
        <Route path="/verify" element={<Verify />} />
      </Routes>
    </EmptyTemplate>
  );
}
