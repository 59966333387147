import {default as View} from "./ClientContractDocumentView";
import ContractDocumentProvider from '../ContractDocumentProvider';

export default function UserContractDocumentView() {
  return (
    <ContractDocumentProvider>
      <View />
    </ContractDocumentProvider>
  );
}
