import BodyText from "components/_common/text/BodyText"

export default function DocumentTitle({title, maxWidth='500px'}) {
  return (
    <BodyText
      sx={{
        fontWeight: '500',
        maxWidth,
        textAlign: 'center',
        marginLeft: 'auto',
        marginRight: 'auto',
        mb: 2,
        p: 1,
        borderRadius: '4px',
        backgroundColor: 'grey.200',
      }}
    >
      {title}
    </BodyText>
  )
}
