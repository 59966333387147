import PropTypes from 'prop-types';
import cx from 'classnames';

import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';

// import './formDialog.scss';

// const useStyles = makeStyles(theme => ({
//   closeIcon: {
//     position: 'relative',
//     right: `-${theme.spacing(2)}px`,
//     top: `-${theme.spacing(1)}px`
//   }
// }));

export default function InfoDialog({
  handleClose,
  isOpen,
  children,
  title,
  className,
  titleClasses,
  contentClasses,
}) {
  // const iconClasses = useStyles();
  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="sm"
        className={cx(className, 'form-dialog')}
      >
        <DialogTitle sx={{pb: 0}}>
          <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
            <Typography variant="h6" sx={{fontWeight: '600'}}>
              {title}
            </Typography>
            <IconButton aria-label="close" onClick={handleClose} sx={{position: 'relative', top: '-8px', right: '-16px'}}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent className={contentClasses}>{children}</DialogContent>
      </Dialog>
    </div>
  );
}

InfoDialog.defaultProps = {
  classes: '',
  titleClasses: '',
  contentClasses: '',
  withCloseIcon: true
}

InfoDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  children: PropTypes.node,
  title: PropTypes.string,
  classes: PropTypes.string,
  titleClasses: PropTypes.string,
  contentClasses: PropTypes.string,
  withCloseIcon: PropTypes.bool,
};
