import axios from 'axios';
import { CONTRACTS_REST_API_URL, RUNTIME_ENV, SITE_URL } from 'config/global';
import { useSelector } from 'react-redux';
// import { firebaseHelpers } from 'utils/firebaseUtils';

export default function useContractsRestAPI() {
  const { auth } = useSelector(state => state.currentUser);

  const options = {
    baseURL: CONTRACTS_REST_API_URL,
    headers: {}
  };

  if (RUNTIME_ENV === 'local') {
    options.headers = {
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': '*',
      'Access-Control-Allow-Credentials': 'true'
    }
  }

  if (auth) {
    options.headers['Authorization'] = `Bearer ${auth?.token?.token}`
  }

  const client = axios.create(options);

  /**
   * if 401 response, refresh token and try again
   */
  // client.interceptors.response.use(undefined, err => {
  //   const error = err.response;

  //   console.log('interceptors.response.use : err', err);

  //   // if error is 401
  //   if (error.status === 401 && error.config && !error.config.__isRetryRequest) {
  //     // request for a new token
  //     return firebaseHelpers.auth.getToken().then(response => {

  //       console.log('getToken : response', response);
  //       // update the error config with new token
  //       error.config.__isRetryRequest = true;
  //       error.config.headers.Authorization = `Bearer ${response.token}`;
  //       return client(error.config);
  //     });
  //   }
  // });

  async function _makeRequest(type, route, data = {}) {
    if (!['get', 'post', 'delete', 'patch'].includes(type)) {
      throw new Error(`${type} is not a supported request type`);
    }

    // if (['post', 'patch'].includes(type) && !Object.keys(data).length) {
    //   throw new Error(`requests of type ${type} require a data object but none was passed or it was empty`);
    // }

    let response = {};

    try {
      switch (type) {
        case 'get':
          response = await client.get(route);
          break;
        case 'post':
          response = await client.post(route, data);
          break;
        case 'put':
          response = await client.put(route, data);
          break;
        case 'delete':
          response = await client.delete(route);
          break;
        default:
          break;
      }

      console.log('[ContractsRestAPI Response]', response);

      return response.data;
    } catch (error) {
      // if (error?.response?.status === 401) {
      //   console.log('401 response');
      //   const newToken = await firebaseHelpers.auth.forceRefreshUser();
      //   console.log('newToken', newToken);
      // }
      handleError(error);
    }
  }

  async function get(route) {
    return _makeRequest('get', route);
  }

  async function post(route, data = {}) {
    return _makeRequest('post', route, data);
  }

  function handleError(error) {
    const parsedAxiosError = parseAxiosError(error);

    // if (error.config?.method) {
    //   console.error('AXIOS_ERROR', {
    //     error: error,
    //     message: error.message,
    //     url: `${error.config.baseURL}${error.config.url}`,
    //     method: error.config.method,
    //     data: error.config.data,
    //     status: error.config.status
    //   });
    // }

    if (parsedAxiosError instanceof Error) {
      parsedAxiosError.message = `[ContractsRestAPI Error] ${parsedAxiosError.message}`;
      throw parsedAxiosError;
    } else {
      throw new Error(`[ContractsRestAPI Error] ${parsedAxiosError}`);
    }
  }

  const contractsRestClient = { post, get };

  return contractsRestClient;
}

const parseAxiosError = (error) => {
  if (error.response) {
    return error;
    // Request made and server responded
    if (error.response.data && error.response.data.message) {
      const errorCode = error.response.data.code || error.response.status;
      return new Error(`${errorCode} - ${error.response.data.message}`);
    } else if (typeof error.response.data === 'string') {
      return new Error(`${error.response.status} - ${error.response.data}`);
    } else {
      return new Error(`${error.response.status} - ${error.response.statusText}`);
    }
  } else if (error.request) {
    // The request was made but no response was received
    console.error('AXIOS_ERROR', error.request);
    return new Error('no response recieved from network request');
    // return error.request;
  } else {
    // Something happened in setting up the request that triggered an Error
    console.error('axios request setup error');
    console.error(error);
    return error;
  }
}
