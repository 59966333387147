import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { ContractDocumentStatus, ContractDocumentRoles } from '@jsr-law/contracts-api';
// import useContractsDataAPI from 'hooks/useContractsDataAPI';
import useContractsRestAPI from 'hooks/useContractsRestAPI';
import { useErrorTracking } from 'hooks/useErrorTracking';
import { useContractDocument } from 'views/ContractDocument/ContractDocumentProvider';
import { Box, AlertTitle, Alert } from '@mui/material';
import contractsAPI from 'api';

import { notices } from './notices';
import { useDeepCompareEffect } from 'react-use';
import DismissableAlert from 'components/_common/DismissableAlert';
import Button from 'components/_common/Button';

const { models } = contractsAPI;

const PandaDocEmbed = () => {
  console.log("pandadoc embed");
  const [{contractDocument, viewerRole}, contractActions] = useContractDocument();
  const [viewerNotice, setViewerNotice] = useState(null);
  const trackError = useErrorTracking()
  const restClient = useContractsRestAPI();

  let viewer;
  let otherRecipient;

  if (contractDocument?.recipients) {
    viewer = contractDocument.recipients.find(rec => rec.role === viewerRole);
    otherRecipient = contractDocument.recipients.find(rec => rec.role !== viewerRole);
  }

  /**
   * Set the notices (if any) based on the viewer and
   * document status
   */
  useDeepCompareEffect(() => {
    if (viewerRole === ContractDocumentRoles.Owner) {
      if (!viewer.has_completed) {
        setViewerNotice(notices[ContractDocumentRoles.Owner].ownerNotCompleted)
      } else if (viewer.has_completed && !otherRecipient.has_completed) {
        setViewerNotice(notices[ContractDocumentRoles.Owner].ownerCompletedAwaitingClient)
      } else if (
        contractDocument.documentStatus === ContractDocumentStatus.Completed
        && contractDocument.signingFeeStripePaymentId
      ) {
        setViewerNotice(notices[ContractDocumentRoles.Owner].documentCompletedPaid)
      } else {
        setViewerNotice(null);
      }
    } else if (viewerRole === ContractDocumentRoles.Client) {
      if (!viewer.has_completed) {
        if (contractDocument.ownerPaySigningFee) {
          setViewerNotice(notices[ContractDocumentRoles.Client].clientNotCompletedOwnerPaid)
        } else {
          setViewerNotice(notices[ContractDocumentRoles.Client].clientNotCompletedAndPayment)
        }
      } else if (
        contractDocument.documentStatus === ContractDocumentStatus.Completed
        && contractDocument.signingFeeStripePaymentId
      ) {
        setViewerNotice(notices[ContractDocumentRoles.Client].documentCompletedPaid)
      } else {
        setViewerNotice(null);
      }
    }
  }, [viewerRole, viewer, otherRecipient, notices])

  /**
   * Handle iFrame postMessage events
   */
  useDeepCompareEffect(() => {
    console.log('PanadocEmbed : postMessage Effect Fired');

    const handleEvent = async event => {
      const type = event.data && event.data.type;
      const payload = event.data && event.data.payload;

      switch (type) {
        case 'session_view.document.loaded':
          contractActions.setEmbedIsReady(true);
          console.log('PandaDoc Session view is loaded');
          break;
        case 'session_view.document.completed':
          console.log('PandaDoc Document is completed', payload);
          if (viewerRole === ContractDocumentRoles.Client)
          await models.contractDocument.update(contractDocument.id, {
            documentStatus: ContractDocumentStatus.Completed
          });
          break;
        case 'session_view.document.exception':
          console.log('PandaDoc Exception during document finalization', payload);
          break;
        default:
      }
    }

    window.addEventListener('message', handleEvent);

    return () => {
      window.removeEventListener('message', handleEvent);
    };
  }, [contractDocument.id, contractActions])

  const { isLoading, isSuccess, data } = useQuery(['contractViewSession', contractDocument.id],
    () => restClient.post('/pandadoc/create-view-session', {
      contractDocumentId: contractDocument.id,
      recipientEmail: viewer.email
    }),
    {
      enabled: !!contractDocument?.id,
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: 1000 * 60 * 60 * 24, // twentyFourHoursInMs
      onError: (error) => {
        // console.error(error);
        console.error(error)
        trackError(error);
        contractActions.setError(true);
      }
    }
  );

  // console.log('contractViewSession query', {
  //   isLoading, isSuccess, isError, error, data
  // });

  if (!isSuccess || isLoading) return null;

  console.log('loading PandaDoc iframe...');

  return (
    <Box
      sx={{
        maxWidth: '800px',
        width: '100%',
      }}
      className="flex items-center flex-col flex-1"
    >
      <Box className="flex-1 w-full" sx={{mb: 0}}>
        {/* {
          viewerRole === ContractDocumentRoles.Client
          && ContractDocumentStatus.Completed === contractDocument.documentStatus
          && contractDocument.collectOwnerPayment
          && !contractDocument.ownerFeeStripePaymentId
            ? <Box sx={{ width: '100%' }}>
                <Alert
                  severity="info"
                  sx={{ mb: 2, width: '100%' }}
                >
                  <AlertTitle>Payment Request from {`${otherRecipient.first_name} ${otherRecipient.last_name}`}</AlertTitle>
                    <Box sx={{mb: 1}}>
                      {`${otherRecipient.first_name} ${otherRecipient.last_name} has requested a payment in the amount of
                      $${contractDocument.ownerPaymentAmount}. Click the Pay Now button to pay securely with your credit or debit card.`}
                    </Box>
                    <Box>
                      <Button
                        variant="outlined" size="small" onClick={contractActions.openOwnerFeeCheckout}>Pay Now</Button>
                    </Box>
                </Alert>
              </Box>
            : viewerNotice
              ? <DismissableAlert severity={viewerNotice.severity} key={viewerNotice.key}>
                  {viewerNotice.title &&
                    <AlertTitle>{viewerNotice.title}</AlertTitle>
                  }
                  {viewerNotice.notice}
                </DismissableAlert>
              : null
        } */}
        {viewerNotice
          ? <DismissableAlert severity={viewerNotice.severity} key={viewerNotice.key}>
              {viewerNotice.title &&
                <AlertTitle>{viewerNotice.title}</AlertTitle>
              }
              {viewerNotice.notice}
            </DismissableAlert>
          : null
        }
      </Box>
      <iframe
        src={`https://app.pandadoc.com/s/${data.id}`}
        title="PandaDoc Embed"
        className=""
        style={{
          width: '100%',
          height: '100%'
        }}
      />

    </Box>
  );
};

export default React.memo(PandaDocEmbed);
