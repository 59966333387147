
/**
 * https://notistack.com/api-reference#mutual-props
 */

export const GENERAL_SNACKBAR_OPTIONS = {
  anchorOrigin: {
    horizontal: 'right',
    vertical: 'bottom'
  },
  preventDuplicate: true,
  autoHideDuration: 3000
}

export const INFO_SNACKBAR_OPTIONS = {
  ...GENERAL_SNACKBAR_OPTIONS,
  variant: 'info'
}

export const SUCCESS_SNACKBAR_OPTIONS = {
  ...GENERAL_SNACKBAR_OPTIONS,
  variant: 'success'
}

export const WARNING_SNACKBAR_OPTIONS = {
  ...GENERAL_SNACKBAR_OPTIONS,
  variant: 'warning'
}

export const ERROR_SNACKBAR_OPTIONS = {
  ...GENERAL_SNACKBAR_OPTIONS,
  variant: 'error'
}
