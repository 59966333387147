import PropTypes from 'prop-types';
import { FastField, Field, ErrorMessage, useFormikContext } from 'formik';
import { Box } from '@mui/material';

/**
 * Wrapper for any fields used with Formik. Displays the field and any errors below
 */
const CustomFormikField = ({
  mb,
  mt,
  name,
  CustomComponent,
  fast,
  errorField,
  fullWidth,
  size,
  showErrorMessage=true,
  ...otherProps
}) => {
  const FormikField = fast ? FastField : Field;
  return (
    <FormikField name={name}>
      {({ field: { value, onChange, onBlur }, meta: { error, touched } }) => {
        return (
          <Box mb={mb} mt={mt}>
            <Box>
              <CustomComponent
                {...{ name, value, onChange, onBlur, ...otherProps }}
                size={size}
                fullWidth={fullWidth}
                error={
                  otherProps.type === 'checkbox'
                    ? error
                    : Boolean(touched && error)
                }
              />
            </Box>
            {showErrorMessage &&
              <Box sx={{ color: 'error.main' }}>
                <ErrorMessage
                  name={name}
                  render={(error) => (
                    <Box
                      sx={{
                        fontSize: 14,
                        fontStyle: 'italic',
                        textAlign: 'left'
                      }}
                    >
                      {errorField ? error[errorField] : error.toString()}
                    </Box>
                  )}
                />
              </Box>
            }
          </Box>
        );
      }}
    </FormikField>
  );
};

CustomFormikField.defaultProps = {
  mb: 0,
  mt: 0,
  fullWidth: false,
  size: 'small'
};

CustomFormikField.propTypes = {
  mb: PropTypes.number, // a number to multiply the theme spacing unit by for the bottom margin
  name: PropTypes.string.isRequired, // form field name
  CustomComponent: PropTypes.elementType.isRequired, // form field component to render
  fast: PropTypes.bool, // use Formik FastField
  errorField: PropTypes.string, // name of field to pull error from
  fullWidth: PropTypes.bool, // make the field full width
};

export default CustomFormikField;
