import { useEffect } from "react";
import { onAuthStateChanged, onIdTokenChanged } from "firebase/auth";
import { useDispatch, useSelector } from "react-redux";
import { setAuthUser, clearUser, setNewToken, setUserProfile } from "redux/slice/authSlice";
import { firebaseAuth } from 'utils/firebaseUtils';
import * as Sentry from '@sentry/react';
import contractsAPI from 'api';

const { models } = contractsAPI;

export default function AuthListener() {
  const user = useSelector((state) => state.currentUser);
  const dispatch = useDispatch();

  const userId = user?.profile?.id;
  const tokenIssuedAtTime = user?.auth?.token?.issuedAtTime;

  useEffect(() => {
    let unsubscribe = () => {};

    if (userId) {
      unsubscribe = models.user.listenOne(
        userId,
        (userDoc) => {
          dispatch(setUserProfile(userDoc))
        }
      );

      return unsubscribe;
    }
  }, [userId, dispatch]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(firebaseAuth, async (auth) => {
      console.info('authStateChanged, updating...');

      if (auth && auth.uid) {
        Sentry.configureScope(function(scope) {
          scope.setUser({
            "email": auth.isAnonymous ? 'anonymous' : auth.email,
            "id": auth.uid
          });
        });

        const { uid, email, emailVerified, isAnonymous } = auth;
        const token = await auth.getIdTokenResult();

        // console.info('authStateChanged : token', token);

        dispatch(setAuthUser({
          uid,
          email,
          emailVerified,
          isAnonymous,
          token,
          roles: token.claims.roles
        }));
      } else {
        dispatch(clearUser());
      }
    });

    return unsubscribe;
  }, [dispatch]);

  useEffect(() => {
    const unsubscribe = onIdTokenChanged(firebaseAuth, async (auth) => {
      console.info('onIdTokenChanged, updating...');

      if (!tokenIssuedAtTime) {
        console.info('onIdTokenChanged : user not previously set, exiting...');
        return;
      }

      const token = await auth.getIdTokenResult();

      // console.log('onIdTokenChanged : token', token);

      if (tokenIssuedAtTime !== token.issuedAtTime) {
        dispatch(setNewToken(token));
      } else {
        console.info('onIdTokenChanged : token.issuedAtTime is the same, exiting... ');
      }

    });

    return unsubscribe;
  }, [dispatch, tokenIssuedAtTime]);
}
