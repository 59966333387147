import { Paper } from "@mui/material";
import { createContext, useState } from "react";
import useContractsDataAPI from "hooks/useContractsDataAPI";
import FormStepper from './FormStepper';

// imports jsdoc typedefs
/// <reference path="types/contracts-api.js" />

export const FormContext = createContext({});

/**
 * @param {Object} props
 * @param {ContractTemplateDocument} props.contractTemplate
 */
function CreateDocumentForm({contractTemplate}) {
  const { enums } = useContractsDataAPI();
  // console.log('enums', enums);
  const ContractDocumentStatus = enums.ContractDocumentStatus
  const [activeStepIndex, setActiveStepIndex] = useState(0);

  const [formData, setFormData] = useState({
    userId: '',
    contractTemplateId: contractTemplate.id,
    title: '',
    createDocumentTermsAccepted: false,
    collectOwnerPayment: false,
    ownerPaymentAmount: 0.00,
    ownerPaySigningFee: false,
    documentStatus: ContractDocumentStatus.Creating,
    recipients: [],
    recipientEmails: []
  });

  // console.log("allFormData", formData);

  return (
    <FormContext.Provider
      value={{ contractTemplate, activeStepIndex, setActiveStepIndex, formData, setFormData }}
    >
      <Paper elevation={12} sx={{p: 4}} className="flex justify-start">
        <FormStepper />
      </Paper>
    </FormContext.Provider>
  );
}

export default CreateDocumentForm;
