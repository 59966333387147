export const themeOptions = {
  palette: {
    mode: 'light',
    primary: {
      main: '#222e2e',
    },
    secondary: {
      main: '#bc2424',
    },
  },
  typography: {
    h1: {
      fontWeight: 700,
      fontSize: '2.5rem',
    },
    h2: {
      fontSize: '2rem',
      fontWeight: 700,
    },
    h3: {
      fontSize: '1.75rem',
      fontWeight: 700,
    },
    h4: {
      fontSize: '1.5rem',
      fontWeight: 700,
    },
    h5: {
      fontWeight: 700,
    },
    subtitle1: {
      fontSize: '1.2rem'
    },
    button: {
      fontSize: '1rem',
      fontWeight: 500,
    },
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
};
