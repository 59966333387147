import { useErrorTracking } from "hooks/useErrorTracking";
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { SUCCESS_SNACKBAR_OPTIONS, ERROR_SNACKBAR_OPTIONS } from 'config/notistack';
import useContractsDataAPI from 'hooks/useContractsDataAPI';
import { Box, Paper } from '@mui/material';
import cx from 'classnames';
import H1 from 'components/_common/text/H1';
import H2 from 'components/_common/text/H2';
import BodyText from 'components/_common/text/BodyText';
import { useContractDocument } from '../ContractDocumentProvider';

import bgImage from 'assets/img/blurred-contract.jpg';
import PandaDocEmbed from 'components/PandaDocEmbed';
import Spinner from 'components/_common/Spinner';
import Subtitle from 'components/_common/text/Subtitle';
import useAppContext from 'hooks/useAppContext';
import useContractsRestAPI from 'hooks/useContractsRestAPI';
import { SUPPORT_EMAIL } from 'config/global';
import Button from 'components/_common/Button';
import DocumentTitle from 'components/ContractDocument/DocumentTitle';
import { useDeepCompareEffect } from 'react-use';
import OwnerPaymentCheckout from "./OwnerPaymentCheckout";

// imports jsdoc typedefs
/// <reference path="types/contracts-api.js" />
/// <reference path="types/misc.js" />

export default function UserContractDocument() {
  const [{contractDocument, hasError, embedIsReady}, contractDocumentActions] = useContractDocument();
  const [appContext, {setActionMenu}] = useAppContext(); // eslint-disable-line
  const { documentId } = useParams();
  const { models, enums } = useContractsDataAPI();
  const contractsRestAPI = useContractsRestAPI();
  const { ContractDocumentStatus, ContractDocumentRoles, ContractPaymentTypes } = enums;
  const trackError = useErrorTracking();
  const documentStatus = contractDocument?.documentStatus;
  const navigate = useNavigate();
  const exitToDashboard = () => navigate('/u/documents');
  const { enqueueSnackbar } = useSnackbar();

  // const ownerInfo = contractDocument?.recipients
  //   ? contractDocument.recipients.find(rec => rec.role === ContractDocumentRoles.Owner)
  //   : null;

  const ownerPaysFee = contractDocument?.ownerPaySigningFee;
  const signingFeePaid = Boolean(contractDocument?.signingFeeStripePaymentId);

  const docCreating = documentStatus === ContractDocumentStatus.Creating;
  const docDraft = documentStatus === ContractDocumentStatus.Draft;
  const docReady = documentStatus === ContractDocumentStatus.Ready;
  const docSent = documentStatus === ContractDocumentStatus.Sent;
  const docCompleted = documentStatus === ContractDocumentStatus.Completed;
  const docPaid = documentStatus === ContractDocumentStatus.Completed && signingFeePaid;
  // const docError = documentStatus === ContractDocumentStatus.Error;

  const canEmbed =
    (!ownerPaysFee && (docReady || docSent || docPaid))
    || (ownerPaysFee && signingFeePaid && (docReady || docSent || docCompleted));

  console.log('canEmbed', canEmbed);
  console.log('signingFeePaid', signingFeePaid);

  const awaitingClientSigningFeePayment = !ownerPaysFee && docCompleted && !signingFeePaid;
  const awaitingOwnerSigningFeePayment = ownerPaysFee && !signingFeePaid;

  /**
   * Configure navbar action menu
   */
  useDeepCompareEffect(() => {
    /**@type {ButtonMenuItem} */
    const resendDocument = {
      key: 'resendDocument',
      text: 'Re-send Singing Request',
      onClick: async () => {
        contractsRestAPI.get(`/documents/${contractDocument.id}/send-to-client`)
          .then(() => {
            enqueueSnackbar('Email Sent', SUCCESS_SNACKBAR_OPTIONS);
          })
          .catch(() => {
            enqueueSnackbar('Error resending signing request', ERROR_SNACKBAR_OPTIONS);
          });
      }
    }

    /**@type {ButtonMenuItem} */
    const resendFinishedDocument = {
      key: 'resendFinishedDocument',
      text: 'Re-send Finished Document',
      onClick: async () => {
        contractsRestAPI.get(`/documents/${contractDocument.id}/send-to-client`)
          .then(() => {
            enqueueSnackbar('Email Sent', SUCCESS_SNACKBAR_OPTIONS);
          })
          .catch(() => {
            enqueueSnackbar('Error resending email', ERROR_SNACKBAR_OPTIONS);
          });
      }
    }

    /**@type {ButtonMenuItem} */
    const exitToDashboard = {
      key: 'exitToDashboard',
      text: 'Exit to Dashbaord',
      onClick: () => navigate('/u/documents')
    }

    if (documentStatus) {
      // console.log('documentStatus', documentStatus)
      switch (documentStatus) {
        case ContractDocumentStatus.Sent:
          setActionMenu({
            enabled: true,
            items: [resendDocument, exitToDashboard]
          });
          break;

        case ContractDocumentStatus.Completed: {
          if (contractDocument?.signingFeeStripePaymentId) {
            setActionMenu({
              enabled: true,
              items: [resendFinishedDocument, exitToDashboard]
            });
          } else {
            setActionMenu({
              enabled: true,
              items: [exitToDashboard]
            });
          }
          break;
        }

        default:
          setActionMenu({
            enabled: true,
            items: [exitToDashboard]
          });
          break;
      }
    }
  }, [documentStatus, contractDocument, ContractDocumentStatus]);

  /**
   * Set the viewer role
   */
  useDeepCompareEffect(() => {
    contractDocumentActions.setViewerRole(ContractDocumentRoles.Owner);
  }, [contractDocumentActions, ContractDocumentRoles]);

  /**
   * Firestore listener
   */
  useDeepCompareEffect(() => {
    const unsubscribe = models.contractDocument.listenOne(
      documentId,
      (data) => {
        console.log('contractDocument update', data);
        contractDocumentActions.setContractDocument(data)
      },
      (error) => {
        // if (error.message === 'not found') {
        //   return navigate('/404')
        // }
        trackError(error);
        console.error(error);
        contractDocumentActions.setError(true);
      }
    );

    return unsubscribe;
  }, [models, contractDocumentActions, trackError]);

  const getLoadingMessage = () => {
    if (!contractDocument) return 'Loading';

    if (contractDocument) {
      if (docCreating || docDraft) {
        return 'Creating Document'
      }

      return 'Loading';
    }
  }

  const Loading = () => (
    <Box className="flex flex-colum justify-center flex-1" sx={{ mt: 2 }}>
      <Box
        className="flex flex-col justify-center items-center flex-1"
        sx={{ position: 'relative' }}
      >
        <Subtitle className="font-medium" sx={{ mb: 1 }}>
          {getLoadingMessage()}
        </Subtitle>
        <Spinner />
      </Box>
    </Box>
  );

  let isLoading =
    ((canEmbed && !embedIsReady) || !contractDocument)
    || (docCreating || docDraft);

  // TODO: handle 404

  return (
    <Box
      className="user-contract-document flex flex-column flex-1"
      sx={{
        backgroundImage: `linear-gradient(180deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 100)), url(${bgImage})`,
        backgroundPosition: 'center top',
        backgroundRepeat: `no-repeat`,
        backgroundSize: 'cover',
        minHeight: '80vh',
        height: '80vh',
      }}
    >
      {hasError
        ? (
        <Box
          className="flex flex-col items-center flex-1"
          sx={{
            maxWidth: '800px',
            margin: '20vh auto 0',
          }}
        >
          <Paper elevation={24} sx={{ p: 4 }}>
            <H1 sx={{ mb: 2, textAlign: 'center' }}>Uh oh!</H1>
            <BodyText sx={{ textAlign: 'center', fontSize: '18px' }}>
              An error occurred while try to fetch the document.
              <br />
              Refresh the page to try again. If you still see this message,
              contact us at{' '}
              <a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a> for help.
            </BodyText>
          </Paper>
        </Box>
      ) : (
        awaitingOwnerSigningFeePayment
          ? <OwnerPaymentCheckout />
          : (
            <>
              {isLoading && <Loading />}
              {(canEmbed || awaitingClientSigningFeePayment) && (
                <Box
                  className={cx('flex flex-col justify-center flex-1', {
                    hidden: !embedIsReady && canEmbed,
                    flex: (embedIsReady && canEmbed) || awaitingClientSigningFeePayment,
                  })}
                  sx={{ mt: 0, mb: 0 }}
                >
                  <Box
                    className="flex flex-col items-center justify-center flex-1"
                    sx={{ position: 'relative' }}
                  >
                    {awaitingClientSigningFeePayment && (
                      <Box
                        className="flex flex-col items-center flex-1"
                        sx={{
                          maxWidth: '600px',
                          margin: '20vh auto 0',
                        }}
                      >
                        <Paper elevation={24} sx={{ p: 4 }} className="text-center">
                          <H2 sx={{ mb: 2, textAlign: 'center' }}>
                            Awaiting Payment
                          </H2>
                          <DocumentTitle title={contractDocument.title} />
                          <BodyText
                            sx={{ textAlign: 'center', fontSize: '18px', mb: 2 }}
                          >
                            This document has been signed by the Other Party but is
                            awaiting their payment. Once the signing fee has been paid
                            this page will update and you will be able to download the
                            signed/finished document.
                          </BodyText>
                          <Button variant="outlined" onClick={exitToDashboard}>
                            Exit to Dashboard
                          </Button>
                        </Paper>
                      </Box>
                    )}
                    {canEmbed && <PandaDocEmbed key={docCompleted ? 'true' : 'false'} />}
                  </Box>
                </Box>
              )}
            </>
          )
      )}

    </Box>
  );
}
