import { getFunctions, httpsCallable, connectFunctionsEmulator } from 'firebase/functions';
import { initializeApp } from 'firebase/app';
import { getDatabase, ref, get, child, connectDatabaseEmulator } from "firebase/database";
import { firebaseConfig } from 'config/firebaseConfig';
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getStorage, connectStorageEmulator } from "firebase/storage";
import { getAuth, signOut, signInAnonymously, getIdTokenResult, connectAuthEmulator } from 'firebase/auth';
import { RUNTIME_ENV, USE_EMULATORS } from 'config/global';

export const initializedFirebaseApp = initializeApp(firebaseConfig);
export const firebaseFunctions = getFunctions(initializedFirebaseApp);
export const firebaseAuth = getAuth(initializedFirebaseApp);
export const realtimeDatabase = getDatabase(initializedFirebaseApp);
export const storage = getStorage(initializedFirebaseApp);
export const firestore = getFirestore(initializedFirebaseApp);

if (RUNTIME_ENV === "local" && USE_EMULATORS === 'true') {
  const EMULATORS_STARTED = 'EMULATORS_STARTED';

  function startEmulators() {
    if (!global[EMULATORS_STARTED]) {
      global[EMULATORS_STARTED] = true;

      connectAuthEmulator(firebaseAuth, "http://localhost:9099");
      connectFunctionsEmulator(firebaseFunctions, "localhost", 5001);
      connectFirestoreEmulator(firestore, 'localhost', 8080);
      connectDatabaseEmulator(realtimeDatabase, 'localhost', 9001);
      connectStorageEmulator(storage, "localhost", 9199);
      connectFirestoreEmulator(firestore, 'localhost', 8080);
    }
  }

  startEmulators();
}

const rtdbRef = ref(realtimeDatabase);

export const cloudFunction = async (functionName, params) => httpsCallable(firebaseFunctions, functionName)(params);

export const firebaseHelpers = {
  functions: {
    cloudFunction: async (functionName, params) => httpsCallable(firebaseFunctions, functionName)(params),
  },
  auth: {
    signOut: async () => signOut(firebaseAuth),
    signInAnonymously: async () => signInAnonymously(firebaseAuth),
    /**
     * @returns {string} JWT
     */
    forceRefreshUser: async () => {
      return getIdTokenResult(firebaseAuth.currentUser, true);
    },
    /**
     * Get's the current user's firebase id token. If it will expire
     * in the next 5 mins it refreshes the token and returns the refreshed
     * token
     * @returns {Promise<string>} firebase id token
     */
    getToken: async () => getIdTokenResult(firebaseAuth.currentUser)
  },
  rtdb: {
    get: async (path) => (await get(child(rtdbRef, path))).val()
  }
};
