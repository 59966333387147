import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import Button, { buttonPropTypes } from '../Button';
import ButtonSpinner from '../ButtonSpinner';

const SubmitButton = forwardRef(
  ({ children, submitting, disabled, fullWidth, ...otherProps }, ref) => (
    <Button ref={ref} disabled={submitting || disabled} fullWidth={fullWidth} {...otherProps}>
      {children}
      {submitting && <ButtonSpinner />}
    </Button>
  )
);

SubmitButton.defaultProps = {
  fullWidth: false,
  submitting: false
};

SubmitButton.propTypes = {
  ...buttonPropTypes,
  submitting: PropTypes.bool,
};

export default SubmitButton;
