import bgImage from 'assets/img/blurred-contract-2.jpg';
import { Box } from '@mui/material';
import AuthCheck from './ClientContractAuthCheck';
import ClientContractDocument from './ClientContractDoc';

// imports jsdoc typedefs
/// <reference path="types/contracts-api.js" />

export default function ClientContractDocumentView() {
  return (
    <Box
      className="user-contract-document flex flex-column flex-1"
      sx={{
        backgroundImage: `linear-gradient(180deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 100)), url(${bgImage})`,
        backgroundPosition: 'center top',
        backgroundRepeat: `no-repeat`,
        backgroundSize: 'cover',
        minHeight: '80vh',
        height: '80vh'
      }}
    >
      <AuthCheck>
        <ClientContractDocument />
      </AuthCheck>
    </Box>
  );
}
