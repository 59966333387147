import React from 'react';
import ReactDOM from 'react-dom/client';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { SnackbarProvider} from "notistack";
import CssBaseline from '@mui/material/CssBaseline';
import { Contracts_DataApi } from '@jsr-law/contracts-api'; // eslint-disable-line
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { initializedFirebaseApp } from 'utils/firebaseUtils';
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { themeOptions } from './theme';
// import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";
import contractsAPI from './api';

import App from './App';
import AuthListener from 'components/AuthListener';
import AuthIsLoaded from 'components/AuthIsLoaded';
import { rootReducer } from "./redux/store";
import { RUNTIME_ENV } from 'config/global';
import './index.css';
import AppContextProvider from 'providers/AppContextProvider';


const fb = initializedFirebaseApp; // eslint-disable-line

// // leaving this commented out here so it is easy to turn back on as needed during dev...
// if (RUNTIME_ENV === "local" && USE_EMULATORS === 'true') {
//   // const functions = getFunctions(initializedFirebaseApp);
//   // connectFunctionsEmulator(functions, "localhost", 5001);

//   // const auth = getAuth(initializedFirebaseApp);
//   // connectAuthEmulator(auth, "http://localhost:9099");

//   // const db = getFirestore(initializedFirebaseApp);
//   // connectFirestoreEmulator(db, 'localhost', 8080);

//   // const rtdb = getDatabase();
//   // connectDatabaseEmulator(rtdb, 'localhost', 9001);

//   // const storage = getStorage(initializedFirebaseApp);
//   // connectStorageEmulator(storage, "localhost", 9199);

//   const EMULATORS_STARTED = 'EMULATORS_STARTED';

//   function startEmulators() {
//     if (!global[EMULATORS_STARTED]) {
//       global[EMULATORS_STARTED] = true;

//       const functions = getFunctions(initializedFirebaseApp);
//       connectFunctionsEmulator(functions, "localhost", 5001);

//       const auth = getAuth(initializedFirebaseApp);
//       connectAuthEmulator(auth, "http://localhost:9099");

//       const db = getFirestore(initializedFirebaseApp);
//       connectFirestoreEmulator(db, 'localhost', 8080);

//       const rtdb = getDatabase(initializedFirebaseApp);
//       console.log('rtdb', rtdb);
//       connectDatabaseEmulator(rtdb, 'localhost', 9001);

//       const storage = getStorage(initializedFirebaseApp);
//       connectStorageEmulator(storage, "localhost", 9199);
//     }
//   }

//   startEmulators();
// }

if (RUNTIME_ENV !== 'local') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_RUNTIME_ENV,
    // release: process.env.REACT_APP_BUILD_NUMBER,
    // ignoreErrors: ['ResizeObserver loop limit exceeded'] // https://forum.sentry.io/t/resizeobserver-loop-limit-exceeded/8402/2

    // This enables automatic instrumentation (highly recommended)
    integrations: [new BrowserTracing()],
    // To set a uniform sample rate
    tracesSampleRate: 0.05
  });

  // console.log('build number', process.env.REACT_APP_BUILD_NUMBER); // injected at build time
}


/** @type {import('react').Context<Contracts_DataApi>} */
export const ContractsAPIContext = React.createContext(null);

const queryClient = new QueryClient();

const store = configureStore({
  devTools: true,
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: {
        extraArgument: { contractsAPI },
      },
      serializableCheck: false,
    }),
});

const muiTheme = createTheme(themeOptions);

if (process.env.NODE_ENV === 'development') {
  console.info('MUI Theme', muiTheme);
}

const root = ReactDOM.createRoot(
  document.getElementById('root')
);

root.render(
  <AppContextProvider>
    <Provider store={store}>
      <ContractsAPIContext.Provider value={contractsAPI}>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={muiTheme}>
                <SnackbarProvider>
                  <AuthListener />
                  <CssBaseline />
                  <AuthIsLoaded>
                    <App />
                  </AuthIsLoaded>
                </SnackbarProvider>
              </ThemeProvider>
            </StyledEngineProvider>
          </BrowserRouter>
          {/* <ReactQueryDevtools initialIsOpen={false} /> */}
        </QueryClientProvider>
      </ContractsAPIContext.Provider>
    </Provider>
  </AppContextProvider>
);
