import StatusBadge from "components/_common/StatusBadge";
import useContractsDataAPI from "hooks/useContractsDataAPI";
import { green, amber, red } from '@mui/material/colors';


export default function DocumentStatusBadge({contractDocument}) {
  const { enums } = useContractsDataAPI();
  const { ContractDocumentStatus } = enums;



  switch (contractDocument.documentStatus) {
    case ContractDocumentStatus.Creating:
    case ContractDocumentStatus.Draft:
      return <StatusBadge text="creating" color={amber[300]} />;
    case ContractDocumentStatus.Ready:
      return <StatusBadge text="ready" color={green[300]} />;
    case ContractDocumentStatus.Sent:
      return <StatusBadge text="sent" color={green[300]} />;
    case ContractDocumentStatus.Completed: {
      if (contractDocument.signingFeeStripePaymentId) {
        return <StatusBadge text="completed" color={green[300]} />;
      } else {
        return <StatusBadge text="awaiting payment" color={amber[300]} />;
      }
    }
    case ContractDocumentStatus.Error:
      return <StatusBadge text="error" color={red[300]} />;
    default:
      return null;
  }
}
