import BasicTemplate from 'components/_common/BasicTemplate';
import { Routes, Route, Navigate } from 'react-router-dom';
// import UserManger from 'views/AdminDashboard/UserManager';
import UserContractDocumentView from 'views/ContractDocument/UserContractDocument';
import ClientContractDocumentView from 'views/ContractDocument/ClientContractDocument';
import PrivateRoute from 'components/_common/PrivateRoute';

export default function ContractDocumentLayout() {
  return (
    <BasicTemplate>
      <Routes>
        <Route path="/:documentId/user" element={<PrivateRoute><UserContractDocumentView /></PrivateRoute>} />
        <Route path="/:documentId/client" element={<ClientContractDocumentView />} />
        <Route path="/*" element={<Navigate to="/404" />} />
      </Routes>
    </BasicTemplate>
  );
}
