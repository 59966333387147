import { useState } from 'react';
import { useDeepCompareEffect } from 'react-use';
import { useSelector } from 'react-redux';
import { Box, Paper } from '@mui/material';

import { useErrorTracking } from "hooks/useErrorTracking";
import { useQueryParams } from 'hooks/useQueryParams';
import H1 from 'components/_common/text/H1';
import BodyText from 'components/_common/text/BodyText';
import Spinner from 'components/_common/Spinner';
import Subtitle from 'components/_common/text/Subtitle';
import useContractsRestAPI from 'hooks/useContractsRestAPI';
import { sleep } from 'utils/sleep';
import { firebaseHelpers } from 'utils/firebaseUtils';
import { SUPPORT_EMAIL } from 'config/global';

// imports jsdoc typedefs
/// <reference path="types/contracts-api.js" />

export default function AuthCheck({children}) {
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isClaimsSet, setIsClaimsSet] = useState(false);
  const { auth } = useSelector(state => state.currentUser);
  const trackError = useErrorTracking();
  const queryParams = useQueryParams();
  const contractsRestAPI = useContractsRestAPI();

  const claims = auth?.token?.claims ?? {};

  // console.log('auth', auth);

  // console.log('claims', claims);

  // console.log('queryParams', queryParams);

  useDeepCompareEffect(() => {
    if (!isLoading) return;

    let decodedEmail = '';

    if (queryParams.cid) {
      decodedEmail = atob(queryParams.cid);
    }

    if (!decodedEmail) {
      setHasError(true);
      trackError('no cid query param');
      console.error('no cid query param found');
    }

    if (!isClaimsSet && claims.clientEmails && claims.clientEmails.includes(decodedEmail)) {
      setIsLoading(false)
      return;
    }

    (async () => {
      if (!auth) {
        console.log('singing in user anonymously')
        await firebaseHelpers.auth.signInAnonymously();
        await sleep(2000);
        return;
      }

      if (auth && !isClaimsSet) {
        console.log('setting user claims')
        await contractsRestAPI.post('/users/add-client-claims', {email: decodedEmail});

        await sleep(2000);

        setIsClaimsSet(true);
      }

      if (auth && isClaimsSet) {
        if (
          !claims.clientEmails
          || (claims.clientEmails && !claims.clientEmails.includes(decodedEmail))
        ) {
          console.log('force refreshing token...');
          await firebaseHelpers.auth.forceRefreshUser();
        }
        setIsLoading(false);
      }
    })();
  }, [queryParams, firebaseHelpers.auth, auth, claims.clientEmails, isLoading, isClaimsSet]);

  // TODO: handle 404

  return (
    <Box className="flex flex-col flex-1">
      {hasError &&
        <Box
          className="flex flex-col items-center flex-1"
          sx={{
            maxWidth: '800px',
            margin: '25vh auto 0',
          }}
        >
          <Paper elevation={24} sx={{p: 4}}>
            <H1 sx={{mb: 2, textAlign: 'center'}}>Uh oh!</H1>
            <BodyText sx={{textAlign: 'center', fontSize: '18px'}}>
              An error occurred while try to verify your access to the document.<br/>
              Refresh the page to try again. If you still see this message, contact us
              at <a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a> for help.
            </BodyText>
          </Paper>
        </Box>
      }
      {(isLoading && !hasError) &&
        <Box
          className="flex flex-colum justify-center flex-1"
          sx={{mt: 2}}
        >
          <Box
            className="flex flex-col justify-center items-center flex-1"
            sx={{position: 'relative'}}
          >
            <Subtitle className="font-medium" sx={{mb: 1}}>
              Authenticating
            </Subtitle>
            <Spinner />
          </Box>
        </Box>
      }
      {(!isLoading && !hasError) && children}
    </Box>
  );
}
