import { useState } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@emotion/react';
import { useMediaQuery } from '@mui/material';
// 3rd party components
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Container from '@mui/material/Container';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';

// import AppBar from './AppBar';
// import Copyright from './Copyright';
// import Drawer from './Drawer';

import { DRAWER_WIDTH } from 'config/global';
import ButtonMenu from './ButtonMenu';
import BodyText from './text/BodyText';
import {ReactComponent as Logo} from '../../assets/img/mc-logo-white.svg';


function DashboardTemplate({navItems, userMenuContent, isAdminDashboard, children}) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [open, setOpen] = useState(false);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  const drawer = (
    <Box
      sx={{
        width: DRAWER_WIDTH,
        mt: {xs: 0, md: '64px'},
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
      role="presentation"
      onClick={toggleDrawer}
      onKeyDown={toggleDrawer}
    >
      {/* <Toolbar
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          px: [1],
        }}
      >
        <IconButton onClick={toggleDrawer}>
          <ChevronLeftIcon />
        </IconButton>
      </Toolbar>
      <Divider /> */}

      <Box
        sx={{ flexGrow: 1 }}
      >
        {/* Primary Nav */}
        <List component="nav">
          {navItems.primary && navItems.primary.map(({name, Icon, route}, idx) => (
            <ListItemButton component={RouterLink} to={route} key={idx}>
              {Icon &&
                <ListItemIcon>
                  <Icon />
                </ListItemIcon>
              }
              <ListItemText primary={name} />
            </ListItemButton>
          ))}
        </List>

        {/* Secondary Nav */}
        {(navItems.secondary && navItems.secondary.length > 0) &&
          <>
            <Divider sx={{ my: 1 }} />
            <List component="nav">
              {navItems.secondary.map(({name, Icon, route}, idx) => (
                <ListItemButton component={RouterLink} to={route} key={idx}>
                  {Icon &&
                    <ListItemIcon>
                      <Icon />
                    </ListItemIcon>
                  }
                  <ListItemText primary={name} />
                </ListItemButton>
              ))}
            </List>
          </>
        }
      </Box>

      {(navItems.bottom && navItems.bottom.length > 0) &&
        <Box>
          {/* Bottom Nav */}
          <List component="nav">
            {navItems.bottom.map(({name, Icon, route}, idx) => (
              <ListItemButton component={RouterLink} to={route} key={idx}>
                {Icon &&
                  <ListItemIcon>
                    <Icon />
                  </ListItemIcon>
                }
                <ListItemText primary={name} />
              </ListItemButton>
            ))}
            {/* {mainListItems}
              <Divider sx={{ my: 1 }} />
            {secondaryListItems} */}
          </List>
        </Box>
      }
    </Box>
  );

  return (
    <Box
      sx={{ display: 'flex' }}
      key="dashboard-template"
    >
      {/* <AppBar position="absolute" open={open}> */}
      <AppBar
        position="fixed"
        sx={{
          zIndex: (theme) => ({
            xs: theme.zIndex.drawer - 1,
            md: theme.zIndex.drawer + 1
          })
        }}
      >
        <Toolbar
          sx={{
            pr: {xs: '10px', sm: '20px'}, // keep right padding when drawer closed
            pl: {xs: '16px', sm: '20px'}
          }}
        >
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
            sx={{
              // marginRight: '36px',
              display: { xs: 'block', md: 'none' },
              // ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          {/* <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            sx={{ flexGrow: 1 }}
          >
            Music Contracts
          </Typography> */}
          <Box sx={{mr: 2, flexGrow: 1, display: 'flex'}}>
            <Box sx={{height: {xs: '30px', md: '38px'}, ml: {xs: 1, md: 0}, display: 'flex', flexShrink: 1}}>
              <Logo style={{width: '100%', height: 'auto'}} />
            </Box>
          </Box>

          <Box sx={{display: 'flex', alignItems: 'center'}}>
            {isAdminDashboard &&
              <BodyText sx={{mr: 2}}>
                Admin {!isSmallScreen && 'Dashboard'}
              </BodyText>
            }
            <ButtonMenu
              Icon={() => <AccountCircleIcon sx={{marginRight: '-4px'}} />}
              menuContent={userMenuContent}
              id="user-account-menu"
              sx={{
                color: "white",
                backgroundColor: 'rgba(255,255,255,.1)',
                '&:hover': { backgroundColor: 'rgba(255,255,255,.2)' },
              }}
            />
          </Box>
        </Toolbar>
      </AppBar>

      <Box
        component="nav"
        sx={{ width: { md: DRAWER_WIDTH }, flexShrink: { md: 0 } }}
      >
        {/* Desktop Left Nav */}
        <Drawer
          key="desktop-nav"
          variant="permanent"
          sx={{
            display: { xs: 'none', md: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: DRAWER_WIDTH },
          }}
          open={false}
        >
          {drawer}
        </Drawer>

        {/* Mobile Menu */}
        <Drawer
          key="mobile-nav"
          variant="temporary"
          anchor="left"
          open={open}
          onClose={toggleDrawer}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', md: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: DRAWER_WIDTH },
          }}
        >
          {drawer}
        </Drawer>
      </Box>

      {/* Main Content Area */}
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto',
          width: { md: `calc(100% - ${DRAWER_WIDTH}px)` },
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <Toolbar /> {/* Just here for top padding */}

        <Container maxWidth={false} sx={{ mt: 4, mb: 4, flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ flexGrow: 1 }} >
            {children}
          </Box>
          {/* <GridContainer>
            <GridItem xs={12}>
              <Paper
                sx={{
                  p: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  minHeight: 240,
                }}
              >
              </Paper>
            </GridItem>
          </GridContainer> */}
          {/* <Copyright sx={{ pt: 4 }} /> */}
        </Container>
      </Box>
    </Box>
  );
}

const _navItemProp = PropTypes.shape({
  name: PropTypes.string.isRequired,
  route: PropTypes.string.isRequired
});

DashboardTemplate.propTypes = {
  navItems: PropTypes.shape({
    primary: PropTypes.arrayOf(_navItemProp).isRequired,
    secondary: PropTypes.arrayOf(_navItemProp).isRequired,
    bottom: PropTypes.arrayOf(_navItemProp).isRequired,
  }),
  userMenuContent: PropTypes.array.isRequired,
  isAdminDashboard: PropTypes.bool
}

export default DashboardTemplate;
