import {
  Navigate,
  useLocation,
  Outlet
} from 'react-router-dom';
import { PropTypes } from 'prop-types';
import { includes } from 'lodash';
import { useSelector } from 'react-redux';

const queryString = require('query-string');

/**
 * A wrapper for components that should only be shown to logged in users. Usage
 * with React Router...
 *
 * <Route
 *   path="/admin"
 *   element={
 *     <PrivateRoute isAllowed={auth.roles.includes('admin')}>
 *       <Private />
 *     </PrivateRoute>
 *   }
 * />
 *
 * @link https://www.robinwieruch.de/react-router-private-routes/
 */
export default function PrivateRoute({ children, isAllowed, redirectPath }) {
  const { auth, isLoading } = useSelector(state => state.currentUser);
  const location = useLocation();
  const urlParams = queryString.parse(location.search);

  if (!auth && isLoading) return null;

  if (redirectPath === '/auth/login') {
    if (location.pathname) {
      redirectPath += `?redirectTo=${encodeURIComponent(location.pathname)}`;
    }

    if (urlParams.loginEmail) {
      let prefix = includes(redirectPath, '?') ? '&' : '?';
      redirectPath += `${prefix}loginEmail=${encodeURIComponent(urlParams.loginEmail)}`;
    }
  }

  if (!auth && !isLoading) {
    return <Navigate to={redirectPath} replace />;
  }

  if (auth && !isLoading && !isAllowed) {
    return <Navigate to={redirectPath} replace />;
  }

  return children ? children : <Outlet />;
}

PrivateRoute.defaultProps = {
  isAllowed: true,
  redirectPath: '/auth/login'
}

PrivateRoute.propTypes = {
  children: PropTypes.node.isRequired,
  isAllowed: PropTypes.bool,
  redirectPath: PropTypes.string.isRequired
};
