import { useMemo } from 'react';
import { useLocation } from "react-router-dom";

// A custom hook that builds on useLocation to parse
// the query string for you.
export function useQueryParams() {
  const { search } = useLocation();

  return useMemo(() => {
    const params = new URLSearchParams(search);

    const result = {}

    for(const [key, value] of params.entries()) { // each 'entry' is a [key, value] tupple
      result[key] = value;
    }
    return result;

  }, [search]);
}
