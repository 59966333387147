// import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./slice/authSlice";
// import { getContractsAPI } from "contractsAPI";

// const contractsAPI = getContractsAPI();

export const rootReducer = {
  currentUser: authReducer
}

// export const store = configureStore({
//   devTools: true,
//   reducer: {
//     currentUser: authReducer,
//   },
//   middleware: (getDefaultMiddleware) =>
//     getDefaultMiddleware({
//       thunk: {
//         extraArgument: contractsAPI,
//       },
//       // serializableCheck: false,
//     }),
// });
