import { useField } from 'formik';
import PropTypes from 'prop-types';
import {
    FormControl,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio
} from '@mui/material';

/**
 * Renders a radio group with Yes/No options that sends back a boolean value
 */
const RadioBool = ({onChange, name, value, error, fullWidth, trueLabel="Yes", falseLabel="No", ...otherProps}) => {
    const [field, meta, helpers] = useField(name); // eslint-disable-line no-unused-vars

    // because radio values can only be strings in the DOM...
    const handleChange = (e) => {
        const newValue = e.target.value === 'true';
        helpers.setValue(newValue);
    };

    // because radio values can only be strings in the DOM...
    const transformValue = () => {
        let tValue = '';

        switch (value) {
            case true:
                tValue = 'true';
                break;
            case false:
                tValue = 'false';
                break;
            default:
                tValue = undefined;
                break;
        };

        return tValue;
    };

    return (
        <FormControl component="fieldset" error={error}>
            <FormLabel component="legend">{otherProps.label}</FormLabel>
            <RadioGroup
                aria-label={name}
                name={name}
                value={transformValue()}
                onChange={handleChange}
                {...otherProps}
                className="flex flex-row"
            >
                <FormControlLabel value="true" control={<Radio />} label={trueLabel} />
                <FormControlLabel value="false" control={<Radio />} label={falseLabel} />
            </RadioGroup>
        </FormControl>
    );
};

RadioBool.propTypes = {
    onChange: PropTypes.func, // onChange from Formik. not used, we are replacing it
    name: PropTypes.string.isRequired, // Formik field name
    value: PropTypes.bool.isRequired, // Formik field value
    error: PropTypes.bool, // field has error
    trueLabel: PropTypes.string,
    falseLabel: PropTypes.string
};

export default RadioBool;
