import { useEffect, useRef, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import PropTypes from 'prop-types';
import CheckoutForm from "./CheckoutForm";
import useContractsRestAPI from "hooks/useContractsRestAPI";
import { STRIPE_PK } from "config/stripe";
import "./checkout.css";
import { Box } from "@mui/material";
import { useDeepCompareEffect } from "react-use";
import { ContractPaymentTypes } from "@jsr-law/contracts-api";
import Spinner from "components/_common/Spinner";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is your test publishable API key.
// const stripePromise = loadStripe(STRIPE_PK);

export default function Checkout({contractDocumentId, customerEmail, customerName, paymentType}) {
  const [clientSecret, setClientSecret] = useState("");
  const apiClient = useContractsRestAPI();
  const stripeSDK = useRef(null);
  // const ownerPaymentType = ContractPaymentTypes.OwnerFee;

  console.log("Checkout : render");

  useEffect(() => {
    stripeSDK.current = loadStripe(STRIPE_PK);
  }, [paymentType]);

  console.log('paymentType', paymentType);

  // TODO: store clientSecret for signingFee and ownerFee with document
  // and attempt to fetch and use those first instead of creating a new
  // payment intent every time the checkout loads so we avoid creating
  // unnecessary payment intents

  useDeepCompareEffect(() => {
    if (!clientSecret) {
      apiClient.post('/stripe/create-payment-intent', {
        documentId: contractDocumentId,
        customerEmail,
        customerName,
        paymentType
      }).then((resp) => setClientSecret(resp.clientSecret));
    }
  }, [contractDocumentId, customerName, customerEmail, paymentType, clientSecret]);

  const appearance = {
    theme: 'stripe',
  };

  const options = {
    clientSecret,
    appearance,
    loader: 'auto'
  };

  return (
    <div>
      {!clientSecret && (
        <Box sx={{my: 3, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <Spinner />
        </Box>
      )}
      {clientSecret && (
        <Box className="checkout-container">
          <Elements options={options} stripe={stripeSDK.current}>
            <CheckoutForm clientSecret={clientSecret} customerEmail={customerEmail} />
          </Elements>
        </Box>
      )}
    </div>
  );
};

Checkout.propTypes = {
  contractDocumentId: PropTypes.string.isRequired,
  customerEmail: PropTypes.string.isRequired,
  customerName: PropTypes.string.isRequired,
  paymentType: PropTypes.oneOf(Object.values(ContractPaymentTypes))
};
