import ContractDocumentProvider from '../ContractDocumentProvider';
import UserContractDocument from './UserContractDocument';

export default function UserContractDocumentView() {
  return (
    <ContractDocumentProvider>
      <UserContractDocument />
    </ContractDocumentProvider>
  )
}
