import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import MuiButton from '@mui/material/Button';

const Button = forwardRef(({children, fullWidth, variant, color, disabled, size, ...otherProps}, ref) => (
  <MuiButton
    ref={ref}
    variant={variant}
    color={color}
    disabled={disabled}
    fullWidth={fullWidth}
    size={size}
    {...otherProps}
  >
    {children}
  </MuiButton>
));

Button.defaultProps = {
  fullWidth: false,
  color: 'primary',
  variant: 'contained',
  size: 'medium'
}

export const buttonPropTypes = {
  chidren: PropTypes.node,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  color: PropTypes.oneOf(["inherit","primary","secondary","error","info","success","warning"]),
  variant: PropTypes.oneOf(["text", "contained", "outlined"])
}

Button.propTypes = buttonPropTypes;

export default Button;
