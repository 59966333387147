import { useField } from 'formik';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

export default function CheckboxBool({name, value, label, onChange}) {
  const [field, meta, helpers] = useField(name); // eslint-disable-line no-unused-vars

  const handleChange = (e) => {
    helpers.setValue(!value);
  };

  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Checkbox
            name={name}
            checked={value}
            onChange={handleChange}
            sx={{p: 0, pr: 1}}
          />
        }
        label={label}
        sx={{display: 'flex', alignItems: 'flex-start'}}
      />
    </FormGroup>
  );
}
